import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { Globals } from '../global';

@Component({
  selector: 'app-query-list',
  templateUrl: './query-list.component.html',
  styleUrls: ['./query-list.component.scss']
})
export class QueryListComponent implements OnInit {
  searchText = "";
  userId = "";
  sessiontoken = ""; arraylist = [];
  showaddbtn = false;
  p = 1; ip = 10;
  type = "All"
  constructor(public rout: Router, private global: Globals, private cookieService: CookieService, private router: Router, private route: ActivatedRoute, private http: HttpClient, private renderer2: Renderer2, @Inject(DOCUMENT) private _document) {
    this.userId = this.cookieService.get("userId");
    this.sessiontoken = this.cookieService.get("sessiontoken");
    if (this.userId != "") {
      this.loadScript1();
      this.getlist();
      setTimeout(() => {
        this.showaddbtn = true;
      }, 500);
    }
    else {
      this.router.navigate([""]);
    }
  }

  ngOnInit() {
    // this.loadScript1();
  }
  loadScript1() {
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.src = '../../assets/js/pages/tbl-datatable-custom1.js';
    s.text = ``;
    this.renderer2.appendChild(this._document.body, s);
  }
  getitemperpage(ip) {
    this.ip = ip;
  }
  getlist() {
    let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
    this.arraylist = [];
    this.http
      .get(this.global.apiUrl + this.global.Querylist, { headers: headers })
      .subscribe(
        api_res => {
          let data = JSON.parse(JSON.stringify(api_res));
         // console.log(data);
          if (data.code == 200) {
            if (data.result.queries != null && data.result.queries.length > 0) {
              for (let i = 0; i < data.result.queries.length; i++) {
                let json = {
                  _id : data.result.queries[i]._id,
                  name : data.result.queries[i].name,
                  mobile: data.result.queries[i].mobile,
                  email: data.result.queries[i].email,
                  organisation: data.result.queries[i].organisation,
                  query : data.result.queries[i].query.length >50?data.result.queries[i].query.slice(0, 50) +"...": data.result.queries[i].query,
                  articleId: data.result.queries[i].articleId,
                  date:data.result.queries[i].createdTime
                }
                this.arraylist.push(json);
              }
            }
          }
        },
        err => {
          // this.global.hideLoading();
          console.log("ERROR!: " + JSON.stringify(err));
          console.log("ERROR!: ", err);
          if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
            console.log("Server is down currently. Please try again after some time");

          }
          else
            if (err.status == "0") {
              console.log("Unable to connect to server due to connectivity issue");

            }
        }
      );
  }
  view(id){
    this.router.navigate(["view-query"], { queryParams: { "_id": id } });
 }
}

