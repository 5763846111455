import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../global';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-view-article',
  templateUrl: './view-article.component.html',
  styleUrls: ['./view-article.component.scss']
})
export class ViewArticleComponent implements OnInit {
    userId = "";listarray=[];selectedFiles?: FileList;
    sessiontoken = "";
    categoryName = "";
    Form: FormGroup;
    submitted = false; _id = "";
    title = ""; isVisible = false;
    description="";
    fullartical="";
    categoryId ="";
    image="";closedDate = new Date();
    minDate= new Date();
    editorConfig: AngularEditorConfig = {
      editable: true,
        spellcheck: true,
        height: 'auto',
        minHeight: '0',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter text here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        fonts: [
          {class: 'arial', name: 'Arial'},
          {class: 'times-new-roman', name: 'Times New Roman'},
          {class: 'calibri', name: 'Calibri'},
          {class: 'comic-sans-ms', name: 'Comic Sans MS'}
        ],
        customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText'
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ],
      uploadUrl: 'v1/image',
      uploadWithCredentials: false,
      sanitize: true,
      toolbarPosition: 'top',
      toolbarHiddenButtons: [
        ['bold', 'italic'],
        ['fontSize']
      ]
    };
    subcategoryarray:any=[];
    Image="";
    Image_url="";
    articalType="Free";
    categoryarray = [];
    showinticker ="true";
    showonBanner="false";
    showonHomepage ="false";
    latestNews ="false";
    tags="";
    subcategoryId="";
    videolink="";
    type="Image";
    Videolink: any;
    constructor( private sanitizer: DomSanitizer,private formBuilder: FormBuilder, private cookieService: CookieService, private router: Router, private http: HttpClient, private route: ActivatedRoute, private renderer2: Renderer2, @Inject(DOCUMENT) private _document, private global: Globals) {
      this.userId = this.cookieService.get("userId");
      this.sessiontoken = this.cookieService.get("sessiontoken");
      this._id = this.route.snapshot.queryParamMap.get("_id");
      if (this.userId != "") {
        if (this._id == null || this._id == undefined) {
        } else {
          this.Details();
        }
      } else {
        this.router.navigate([""]);
      }
      this.categorylist();
    }
    ngOnInit() {
      this.Form = this.formBuilder.group({
        title : ['', Validators.required],
        description : ['', Validators.required],
        fullartical : ['', Validators.required],
        categoryId : ['', Validators.required],
        Image:[''],
        closedDate:['', Validators.required],
        articalType:[''],
        showinticker:[''],
          showonBanner:[''],
          showonHomepage :[''],
          latestNews:[''],
          tags:[''],
          subcategoryId:[''],
          videolink: [''],
          type:['']
      })
    }
    get f() { return this.Form.controls; }
    Update() {
      let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
      this.submitted = true;
      if (this.Form.invalid) {
        return;
      } else {
        let data = {
          id: this._id,
          title : this.title,
        description : this.description,
        fullartical :this.fullartical,
        categoryId : this.categoryId,
        subcategoryId : this.subcategoryId,
        Image:this.Image,
        closedDate : this.closedDate,
        modifiedby: this.userId,
        articalType:this.articalType,
        showinticker : this.showinticker,
        showonBanner :this.showonBanner,
        showonHomepage  : this.showonHomepage,
        latestNews : this.latestNews,
        tags:this.tags.split(','),
        videolink:this.videolink
        };
       // console.log(this.global.apiUrl + this.global.Artical, data, { headers: headers })
        this.http
          .put(this.global.apiUrl + this.global.Artical, data, { headers: headers })
          .subscribe(
            api_res => {
              // this.global.hideLoading();
              let data = JSON.parse(JSON.stringify(api_res));
              //console.log(data)
              if (data.code == 200) {
                // this.hideLoader();
                if (data.result.artical !== null || data.result.artical !== undefined) {
                  alert("Article updated successfully.");
                  this.router.navigate(['article-list']);
                } else {
                  alert("Something went wrong, please try again.");
                }
              } else {
                alert("Something went wrong, please try again.");
              }
            },
            err => {
              console.log("ERROR!: ", err);
              console.log("ERROR!: ", err);
              if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
                // this.hideLoader();
                console.log("Server is down currently. Please try again after some time");
  
              }
              else
                if (err.status == "0") {
                  // this.hideLoader();
                  console.log("Unable to connect to server due to connectivity issue");
  
                }
            }
          );
  
      }
  
    }
    Add() {
      let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
      this.submitted = true;
      if (this.Form.invalid) {
        return;
      } else {
        let data = {
          title : this.title,
          description : this.description,
          fullartical :this.fullartical,
          categoryId : this.categoryId,
          subcategoryId : this.subcategoryId,
          Image:this.Image,
          createdby: this.userId,
          closedDate:this.closedDate,
          articalType:this.articalType,
          showinticker : this.showinticker,
          showonBanner :this.showonBanner,
          showonHomepage  : this.showonHomepage,
          latestNews : this.latestNews,
          tags:this.tags.split(','),
          videolink: this.videolink
        };
        //console.log(this.global.apiUrl + this.global.faq, data, { headers: headers })
        this.http
          .post(this.global.apiUrl + this.global.Artical, data, { headers: headers })
          .subscribe(
            api_res => {
              // this.global.hideLoading();
              let data = JSON.parse(JSON.stringify(api_res));
              //console.log(data)
              if (data.code == 200) {
                if (data.result.artical.title == null || data.result.artical.title == undefined) {
                  alert(data.result.artical);
                }else{
                  alert("Article added successfully.");
                  this.router.navigate(['article-list']);
                 }
              } else {
                alert("Something went wrong, please try again.");
              }
            },
            err => {
              console.log("ERROR!: ", err);
              console.log("ERROR!: ", err);
              if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
                // this.hideLoader();
                console.log("Server is down currently. Please try again after some time");
  
              }
              else
                if (err.status == "0") {
                  // this.hideLoader();
                  console.log("Unable to connect to server due to connectivity issue");
  
                }
            }
          );
  
      }
  
    }
    categorylist() {
      let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
      this.categoryarray = [];
      this.http
        .get(this.global.apiUrl + this.global.ArticalCategorylist, { headers: headers })
        .subscribe(
          api_res => {
            //  this.global.hideLoading();
            let data = JSON.parse(JSON.stringify(api_res));
            if (data.code == 200) {
              if (data.result.articalcategories.length > 0) {
                this.categoryarray = data.result.articalcategories;
              }
            }
          },
          err => {
            // this.global.hideLoading();
            console.log("ERROR!: " + JSON.stringify(err));
            console.log("ERROR!: ", err);
            if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
              console.log("Server is down currently. Please try again after some time");
  
            }
            else
              if (err.status == "0") {
                console.log("Unable to connect to server due to connectivity issue");
              }
          }
        );
  
    }
    Details() {
      let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
      this.http
        .get(this.global.apiUrl + this.global.ArticalDetails + this._id, { headers: headers })
        .subscribe(
          api_res => {
            // this.global.hideLoading();
            let data = JSON.parse(JSON.stringify(api_res));
            //console.log(data)
            if (data.code == 200) {
              if (data.result.artical !== null || data.result.artical !== undefined) {
                this.categoryId = data.result.artical.categoryId==null?"": data.result.artical.categoryId._id;
                this.subcategorylist(this.categoryId);
                this.subcategoryId = data.result.artical.subcategoryId;
                this.title = data.result.artical.title;
                this.description= data.result.artical.description;
                this.fullartical= data.result.artical.fullartical;
                this.closedDate =data.result.artical.closedDate;
                this.articalType = data.result.artical.articalType;
                this.showinticker = data.result.artical.showinticker.toString();
                this.showonBanner = data.result.artical.showonBanner.toString(),
                this.showonHomepage = data.result.artical.showonHomepage.toString(),
                this.latestNews= data.result.artical.latestNews.toString();
                this.Image = data.result.artical.Image,
                this.Image_url= this.global.imageUrl1 + data.result.artical.Image;
                this.tags=data.result.artical.tags.join(",");
                this.videolink =data.result.artical.videolink;
                if(data.result.artical.videolink==null||data.result.artical.videolink==undefined||data.result.artical.videolink==""){}else{
                  var videoId = this.YouTubeGetID(data.result.artical.videolink);
                  this.Videolink = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/" + videoId + "?controls=0");
               this.type = "Video";
                }
              } else {
                alert("Something went wrong, please try again.");
              }
            } else {
              alert("Something went wrong, please try again.");
            }
          },
          err => {
            console.log("ERROR!: ", err);
            console.log("ERROR!: ", err);
            if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
              // this.hideLoader();
              console.log("Server is down currently. Please try again after some time");
            }
            else
              if (err.status == "0") {
                // this.hideLoader();
                console.log("Unable to connect to server due to connectivity issue");
              }
          }
        );
    }
    selectFiles(event: any): void {
      this.selectedFiles = event.target.files;
      if (this.selectedFiles && this.selectedFiles[0]) {
        const numberOfFiles = this.selectedFiles.length;
        for (let i = 0; i < numberOfFiles; i++) {
          const reader = new FileReader();
  
          reader.onload = (e: any) => {
            //  console.log(e.target.result);
            /// this.previews.push(e.target.result);
          };
  
          reader.readAsDataURL(this.selectedFiles[i]);
        }
        this.uploadFiles();
      }
    }
    uploadFiles(): void {
      if (this.selectedFiles) {
        for (let i = 0; i < this.selectedFiles.length; i++) {
          this.upload(i, this.selectedFiles[i]);
        }
      }
    }
    upload(idx: number, file: File): void {
      // this.progressInfos[idx] = { value: 0, fileName: file.name };
      if (file) {
        const formData: FormData = new FormData();
        formData.append('file', file);
        this.http
          .post<any>(this.global.imageuploadUrl, formData, {
            reportProgress: true,
            responseType: 'json'
          }).subscribe(response => {
            //console.log(response)
            if (response.statusCode === 200) {
              this.Image = response.filename;
              this.Image_url = this.global.imageUrl1 + response.filename;
              //this.fileInputLabel = "";
            }
          }, er => {
            console.log(er);
            alert(er.error.error);
          });
      }
    }
    subcategorylist(categoryId) {
      let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
      this.subcategoryarray = [];
      this.http
        .get(this.global.apiUrl + this.global.ArticalSubcategorylist +categoryId +"/", { headers: headers })
        .subscribe(
          api_res => {
            //  this.global.hideLoading();
            let data = JSON.parse(JSON.stringify(api_res));
            //console.log(data)
            if (data.code == 200) {
              if (data.result.subcategories.length > 0) {
                this.subcategoryarray = data.result.subcategories;
              }
            }
          },
          err => {
            // this.global.hideLoading();
            console.log("ERROR!: " + JSON.stringify(err));
            console.log("ERROR!: ", err);
            if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
              console.log("Server is down currently. Please try again after some time");
  
            }
            else
              if (err.status == "0") {
                console.log("Unable to connect to server due to connectivity issue");
              }
          }
        );
  
    }
    getlink() {
      if (this.videolink != "") {
        var videoId = this.YouTubeGetID(this.videolink);
        this.Videolink = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/" + videoId + "?controls=0");
      }
    }
    YouTubeGetID(url: any) {
      url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
    }
    gettype(type:any){
      if(type=="Image"){
        this.Videolink ="";
        this.videolink = "";
      }else{
        this.Image="";
        this.Image_url="";
      }
    }
  }
  
  
