<div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Article List</h4>
          <div class="mt-3">
            <a type="submit" class="btn btn-primary font-weight-medium auth-form-btn float-right mb-3"
              [routerLink]="'/article'">Add Article</a>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-4 col-4">
              <div class="form-group">
                  <select type="text" class="form-control" id="exampleInputName1"
                      [(ngModel)]="type" name="type" (change)="gettype(type)">
                      <option value="All">All Article</option>
                      <option value="Paid">Paid Article</option>
                      <option value="Free">Free Article</option>
                  </select>
                 
              </div>
          </div>
            <div class="col-lg-4 col-md-4 col-4" *ngIf="listarray.length>0">
              <div class="form-group">
                <input type="text" class="form-control" [(ngModel)]="searchText" placeholder="Search">
              </div>
            </div>
          </div>
          <div class="table-responsive" *ngIf="listarray.length>0">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Action</th>
                  <th>Title</th>
                  <th>Category</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let item of listarray| filter:searchText | paginate: { itemsPerPage:ip, currentPage: p }; let i=index">
                  <td>{{i+1}}</td>
                  <td><button type="button" class="btn btn-primary btn-rounded btn-icon" (click)="Edit(item._id)">
                      <i class="ti-pencil"></i>
                    </button>&nbsp;
                    <button type="button" class="btn btn-danger btn-rounded btn-icon" (click)="showAlert(item._id)">
                      <i class="ti-trash"></i>
                    </button>
                  </td>
                  <td>{{item.title}}</td>
                  <td>{{item.categoryId.categoryName}}</td>
                </tr>
              </tbody>
            </table>
            <pagination-controls class="pagination float-right mt-1" (pageChange)="p = $event">
            </pagination-controls>
            <div class="row mt-1">
              <div class="col-lg-3 col-md-3 col-3">
                <div class="form-group">
                  <select class="form-control form-control-sm" id="exampleFormControlSelect3" [(ngModel)]="ip" name="ip"
                    (change)="getitemperpage(ip)">
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center card-empty" *ngIf="listarray.length==0">No Data Available</div>
        </div>
      </div>
    </div>
  </div>