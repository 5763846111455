import { Component, OnInit, Renderer2, Inject } from '@angular/core';
  import { Router, ActivatedRoute } from '@angular/router';
  import { HttpClient } from '@angular/common/http';
  import { Globals } from '../global';
  import { DOCUMENT } from '@angular/common';
  import { CookieService } from 'ngx-cookie-service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ConfirmedValidator } from '../Confirmed.validator';
@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {
    userId = "";
    sessiontoken = "";
    oldPwd = "";
    newPwd = "";
    CnewPwd = "";
    Form: FormGroup;
    submitted= false;
    title="";isVisible = false;
    constructor(private formBuilder: FormBuilder,private cookieService: CookieService, private router: Router, private http: HttpClient, private route: ActivatedRoute, private renderer2: Renderer2, @Inject(DOCUMENT) private _document, private global: Globals) {
      this.userId = this.cookieService.get("userId");
      this.sessiontoken = this.cookieService.get("sessiontoken");
      if (this.userId != "") {
      }else {
        this.router.navigate([""]);
      }

    }
    ngOnInit() {
      this.Form = this.formBuilder.group({
        oldPwd: ['', Validators.required],
        newPwd: ['', Validators.required],
        CnewPwd: ['', Validators.required],
      }, { 
        validator: ConfirmedValidator('newPwd', 'CnewPwd')
      })
    }
    
    get f() { return this.Form.controls; }
    UpdatePassword() {
      let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
      this.submitted = true;
    if (this.Form.invalid) {
      return;
    }else {
        let data = {
          "old_password": this.oldPwd,
           "new_password": this.newPwd, 
           "userId": this.userId,
        };
       // console.log(this.global.apiUrl + this.global.adminpin, data, { headers: headers })
        this.http
          .put(this.global.apiUrl + this.global.adminpin, data, { headers: headers })
          .subscribe(
            api_res => {
              // this.global.hideLoading();
              let data = JSON.parse(JSON.stringify(api_res));
             // console.log(data)
              if (data.code == 200) {
                // this.hideLoader();
                var title ="Password changed successfully.";
                this.onOpenDialog(title);
              }
              else if (data.code == 704) {
                var title ="Invalid current password.";
                this.OpenDialog(title);
              }
              else {
                var title ="Something went wrong, please try again.";
                this.OpenDialog(title);
              }
            },
            err => {
              console.log("ERROR!: ", err);
              console.log("ERROR!: ", err);
              if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
                // this.hideLoader();
                console.log("Server is down currently. Please try again after some time");
  
              }
              else
                if (err.status == "0") {
                  // this.hideLoader();
                  console.log("Unable to connect to server due to connectivity issue");
  
                }
            }
          );
  
      }
  
    }
  
    onOpenDialog(title) {
      this.title = title;
      if (this.isVisible) {
        return;
      }
      this.isVisible = true;
      setTimeout(()=> {
      this.isVisible = false;
      this.router.navigate([""]);
      },1000);
      
    }
    OpenDialog(title) {
      this.title = title;
      if (this.isVisible) {
        return;
      }
      this.isVisible = true;
      setTimeout(()=> {
   this.isVisible = false;
      },1000);
      
    }
  
  }
  
  
  
  
  
  
  
  
  
  
  
  
