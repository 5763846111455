<div class="row">
    <div class="col-md-12 grid-margin">
        <div class="row">
            <div class="col-8 col-xl-8 mb-4 mb-xl-0">
                <h3 class="font-weight-bold">Welcome {{name}}</h3>
            </div>
            <div class="col-4 col-xl-4">
                <div class="justify-content-end d-flex">
                    <div class="dropdown flex-md-grow-1 flex-xl-grow-0">
                        Today ({{date| date:'dd MMM yyyy'}})
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12 grid-margin transparent">
        <div class="row">
            <div class="col-md-4 mb-4 stretch-card transparent">
                <div class="card card-tale">
                    <a (click)="view('All')">
                        <div class="card-body">
                            <p class="mb-4">All Users</p>
                            <p class="fs-30 mb-2">{{AllUser}}</p>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-md-4 mb-4 stretch-card transparent">
                <div class="card card-green">
                    <a  (click)="view('Paid')">
                        <div class="card-body">
                            <p class="mb-4">Paid Users</p>
                            <p class="fs-30 mb-2">{{PaidUsers}}</p>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-md-4 mb-4 stretch-card transparent">
                <div class="card card-dark-blue">
                    <a (click)="view('Free')">
                        <div class="card-body">
                            <p class="mb-4">Free Users</p>
                            <p class="fs-30 mb-2">{{FreeUsers}}</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 mb-4 stretch-card transparent">
                <div class="card card-light-danger">
                    <a (click)="View('All')">
                        <div class="card-body">
                            <p class="mb-4">All Articles</p>
                            <p class="fs-30 mb-2">{{AllArticles}}</p>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-md-4 mb-4 stretch-card transparent">
                <div class="card card-light-blue">
                    <a (click)="View('Free')">
                        <div class="card-body">
                            <p class="mb-4">Free Articles</p>
                            <p class="fs-30 mb-2">{{FreeArticles}}</p>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-md-4 mb-4 stretch-card transparent">
                <div class="card card-yellow">
                    <a (click)="View('Paid')">
                        <div class="card-body">
                            <p class="mb-4">Paid Articles</p>
                            <p class="fs-30 mb-2">{{PaidArticles}}</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-md-4 mb-4 mb-lg-0 stretch-card transparent">
                <div class="card card-primary-blue">
                    <a  [routerLink]="'/todays-viewed-articles'">
                        <div class="card-body">
                            <p class="mb-4">Todays Viewed Articles</p>
                            <p class="fs-30 mb-2">{{todaysarticles}}</p>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-md-4 mb-4 mb-lg-0 stretch-card transparent">
                <div class="card card-red">
                    <a  [routerLink]="'/weeks-viewed-articles'">
                        <div class="card-body">
                            <p class="mb-4">This Weeks Viewed Articles</p>
                            <p class="fs-30 mb-2">{{lastweekarticles}}</p>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-md-4 mb-4 mb-lg-0 stretch-card transparent">
                <div class="card card-primary-green">
                    <a  [routerLink]="'/months-viewed-articles'">
                        <div class="card-body">
                            <p class="mb-4">This Months Viewed Articles</p>
                            <p class="fs-30 mb-2">{{monthsarticles}}</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 mb-4 mb-lg-0 stretch-card transparent">
                <div class="card card-primary-yellow">
                    <a  [routerLink]="'/all-viewed-articles'">
                        <div class="card-body">
                            <p class="mb-4">All Viewed Articles</p>
                            <p class="fs-30 mb-2">{{allarticles}}</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>