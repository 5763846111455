import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Globals } from './global';
import { CookieService } from 'ngx-cookie-service';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MainmasterComponent } from './mainmaster/mainmaster.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { AddArticalCategoryComponent } from './add-artical-category/add-artical-category.component';
import { AddArticalComponent } from './add-artical/add-artical.component';
import { ArticalCategoryListComponent } from './artical-category-list/artical-category-list.component';
import { ArticalListComponent } from './artical-list/artical-list.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SettingComponent } from './setting/setting.component';
import { SubscriptionPlansComponent } from './subscription-plans/subscription-plans.component';
import { SubscriptionPlanComponent } from './subscription-plan/subscription-plan.component';
import { SubcategoryListComponent } from './subcategory-list/subcategory-list.component';
import { AddSubcategoryComponent } from './add-subcategory/add-subcategory.component';
import { SubmitRequestListComponent } from './submit-request-list/submit-request-list.component';
import { ChangeSubmitstatusComponent } from './change-submitstatus/change-submitstatus.component';
import { UsersListComponent } from './users-list/users-list.component';
import { TodaysArticlesComponent } from './todays-articles/todays-articles.component';
import { WeekArticlesComponent } from './week-articles/week-articles.component';
import { MonthsArticlesComponent } from './months-articles/months-articles.component';
import { AllViewedArticlesComponent } from './all-viewed-articles/all-viewed-articles.component';
import { ViewArticleComponent } from './view-article/view-article.component';
import { QueryListComponent } from './query-list/query-list.component';
import { ProfileComponent } from './profile/profile.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { ViewQueryComponent } from './view-query/view-query.component';
import { AddAdminComponent } from './add-admin/add-admin.component';
import { AdminListComponent } from './admin-list/admin-list.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ChangeSubmitstatusComponent,
    MainmasterComponent,
    DashboardComponent,
    AddArticalCategoryComponent,
    AddArticalComponent,
    ArticalCategoryListComponent,
    ArticalListComponent,
    SettingComponent,
    SubscriptionPlansComponent,
    SubscriptionPlanComponent,
    SubcategoryListComponent,
    AddSubcategoryComponent,
    SubmitRequestListComponent,
    UsersListComponent,
    TodaysArticlesComponent,
    WeekArticlesComponent,
    MonthsArticlesComponent,
    AllViewedArticlesComponent,
    ViewArticleComponent,
    QueryListComponent,
    ProfileComponent,
    ConfigurationComponent,
    ViewQueryComponent,
    AddAdminComponent,
    AdminListComponent
  ],
  imports: [
    AngularEditorModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    NgxPaginationModule,
    DatePickerModule
  ],
  providers: [Globals, CookieService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
