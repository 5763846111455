<div class="row">
    <div class="col-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">View Query</h4>
                <form class="forms-sample">
                    <div class="form-group">
                        <label for="exampleInputName1">Query</label>
                        <textarea type="text" class="form-control" id="exampleInputName1" placeholder="message"
                            [(ngModel)]="message" name="message"></textarea>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputName1">Article Title</label>
                        <input type="text" class="form-control" id="exampleInputName1" placeholder="Article Title"
                            [(ngModel)]="title" name="title">
                    </div>
                    <div class="form-group">
                        <label for="exampleInputName1">Name</label>
                        <input type="text" class="form-control" [(ngModel)]="name" name="name" id="exampleInputName1"
                            placeholder="Name">
                    </div>
                    <div class="form-group">
                        <label for="exampleInputName1">Phone Number</label>
                        <input type="text" class="form-control" id="exampleInputName1" placeholder="Phone Number"
                            [(ngModel)]="mobile" name="mobile">
                    </div>
                    <div class="form-group">
                        <label for="exampleInputName1">Email</label>
                        <input type="text" class="form-control" id="exampleInputName1" placeholder="Email"
                            [(ngModel)]="email" name="email">
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>