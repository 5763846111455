import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../global';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-add-artical-category',
  templateUrl: './add-artical-category.component.html',
  styleUrls: ['./add-artical-category.component.scss']
})
export class AddArticalCategoryComponent implements OnInit {
  userId = "";
  sessiontoken = "";
  categoryName = "";
  Form: FormGroup;
  submitted = false; _id = "";
  title = ""; isVisible = false;
  constructor(private formBuilder: FormBuilder, private cookieService: CookieService, private router: Router, private http: HttpClient, private route: ActivatedRoute, private renderer2: Renderer2, @Inject(DOCUMENT) private _document, private global: Globals) {
    this.userId = this.cookieService.get("userId");
    this.sessiontoken = this.cookieService.get("sessiontoken");
    this._id = this.route.snapshot.queryParamMap.get("_id");
    if (this.userId != "") {
      if (this._id == null || this._id == undefined) {
      } else {
        this.Details();
      }
    } else {
      this.router.navigate([""]);
    }
  }
  ngOnInit() {
    this.Form = this.formBuilder.group({
      categoryName: ['', Validators.required],
    })
  }

  get f() { return this.Form.controls; }
  Update() {
    let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
    this.submitted = true;
    if (this.Form.invalid) {
      return;
    } else {
      let data = {
        "id": this._id,
        "categoryName": this.categoryName,
        "modifiedby": this.userId
      };
      //console.log(this.global.apiUrl + this.global.faq, data, { headers: headers })
      this.http
        .put(this.global.apiUrl + this.global.ArticalCategory, data, { headers: headers })
        .subscribe(
          api_res => {
            // this.global.hideLoading();
            let data = JSON.parse(JSON.stringify(api_res));
           // console.log(data)
            if (data.code == 200) {
              // this.hideLoader();
              if (data.result.articalcategory !== null || data.result.articalcategory !== undefined) {
                alert("Article Category updated successfully.");
                this.router.navigate(['article-category-list']);
              } else {
                alert("Something went wrong, please try again.");
              }
            } else {
              alert("Something went wrong, please try again.");
            }
          },
          err => {
            console.log("ERROR!: ", err);
            console.log("ERROR!: ", err);
            if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
              // this.hideLoader();
              console.log("Server is down currently. Please try again after some time");

            }
            else
              if (err.status == "0") {
                // this.hideLoader();
                console.log("Unable to connect to server due to connectivity issue");

              }
          }
        );

    }

  }
  Add() {
    let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
    this.submitted = true;
    if (this.Form.invalid) {
      return;
    } else {
      let data = {
        "categoryName": this.categoryName,
        "createdby": this.userId
      };
      //console.log(this.global.apiUrl + this.global.faq, data, { headers: headers })
      this.http
        .post(this.global.apiUrl + this.global.ArticalCategory, data, { headers: headers })
        .subscribe(
          api_res => {
            // this.global.hideLoading();
            let data = JSON.parse(JSON.stringify(api_res));
           // console.log(data)
            if (data.code == 200) {
              if (data.result.articalcategory.categoryName == null || data.result.articalcategory.categoryName == undefined) {
                alert(data.result.articalcategory);
              }else{
                alert("Article Category added successfully.");
                this.router.navigate(['article-category-list']);
               }
            } else {
              alert("Something went wrong, please try again.");
            }
          },
          err => {
            console.log("ERROR!: ", err);
            console.log("ERROR!: ", err);
            if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
              // this.hideLoader();
              console.log("Server is down currently. Please try again after some time");

            }
            else
              if (err.status == "0") {
                // this.hideLoader();
                console.log("Unable to connect to server due to connectivity issue");

              }
          }
        );

    }

  }
  Details() {
    let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
    this.http
      .get(this.global.apiUrl + this.global.ArticalCategoryDetails + this._id, { headers: headers })
      .subscribe(
        api_res => {
          // this.global.hideLoading();
          let data = JSON.parse(JSON.stringify(api_res));
         // console.log(data)
          if (data.code == 200) {
            if (data.result.articalcategory !== null || data.result.articalcategory !== undefined) {
              this.categoryName = data.result.articalcategory.categoryName;
            } else {
              alert("Something went wrong, please try again.");
            }
          } else {
            alert("Something went wrong, please try again.");
          }
        },
        err => {
          console.log("ERROR!: ", err);
          console.log("ERROR!: ", err);
          if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
            // this.hideLoader();
            console.log("Server is down currently. Please try again after some time");
          }
          else
            if (err.status == "0") {
              // this.hideLoader();
              console.log("Unable to connect to server due to connectivity issue");
            }
        }
      );
  }
}

