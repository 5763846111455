import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { Globals } from '../global';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  orderStatusid = "";
  searchText = "";
  userId = "";
  sessiontoken = "";arraylist = [];
  showaddbtn = false;
  p = 1; ip = 10;
  type="All"
  constructor(public rout: Router, private global: Globals, private cookieService: CookieService, private router: Router, private route: ActivatedRoute, private http: HttpClient, private renderer2: Renderer2, @Inject(DOCUMENT) private _document) {
    this.userId = this.cookieService.get("userId");
    this.sessiontoken = this.cookieService.get("sessiontoken");
    console.log(this.route.snapshot.queryParamMap.get("type"))
if(this.route.snapshot.queryParamMap.get("type")==null||this.route.snapshot.queryParamMap.get("type")==undefined){}else{
    this.type = this.route.snapshot.queryParamMap.get("type");
}
    // alert(this.userid);
    if (this.userId != "") {
      this.loadScript1();
      this.getUserlist();
      setTimeout(() => {
        this.showaddbtn = true;
      }, 500);
    }
    else {
      this.router.navigate([""]);
    }
  }

  ngOnInit() {
    // this.loadScript1();
  }
  loadScript1() {
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.src = '../../assets/js/pages/tbl-datatable-custom1.js';
    s.text = ``;
    this.renderer2.appendChild(this._document.body, s);
  }
  getitemperpage(ip) {
    this.ip = ip;
  }
  getUserlist(){
    let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
    this.arraylist = [];
    this.http
      .get(this.global.apiUrl + this.global.userlist + this.type , { headers: headers })
      .subscribe(
        api_res => {
          let data = JSON.parse(JSON.stringify(api_res));
          //console.log(data);
          if (data.code == 200) {
            this.arraylist = [];
            if (data.result.users!= null && data.result.users.length > 0) {
             this.arraylist = data.result.users;
          }
          }
        },
        err => {
          // this.global.hideLoading();
          console.log("ERROR!: " + JSON.stringify(err));
          console.log("ERROR!: ", err);
          if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
            console.log("Server is down currently. Please try again after some time");

          }
          else
            if (err.status == "0") {
              console.log("Unable to connect to server due to connectivity issue");

            }
        }
      );
  }
  gettype(type){
    this.type = type;
    this.getUserlist();
  }
}
