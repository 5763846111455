import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Globals } from '../global';

@Component({
  selector: 'app-months-articles',
  templateUrl: './months-articles.component.html',
  styleUrls: ['./months-articles.component.scss']
})
export class MonthsArticlesComponent implements OnInit {
    p = 1; ip = 10; searchText;
    Id = "";
    userId = "";
    sessiontoken = "";
    listarray = [];
    title = ""; isVisible = false;
    type="All";
    constructor(private cookieService: CookieService, private router: Router, private http: HttpClient, private route: ActivatedRoute, private global: Globals, private renderer2: Renderer2, @Inject(DOCUMENT) private _document) {
      this.userId = this.cookieService.get("userId");
      this.sessiontoken = this.cookieService.get("sessiontoken");   
      if (this.userId != "") {
        this.articallist();
      }
    }
    ngOnInit() {
    }
    articallist() {
      let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
      this.listarray = [];
      this.http
        .get(this.global.apiUrl + this.global.MonthsViewedArticles, { headers: headers })
        .subscribe(
          api_res => {
            //  this.global.hideLoading();
            let data = JSON.parse(JSON.stringify(api_res));
            if (data.code == 200) {
              if (data.result.articles.length > 0) {
                this.listarray = data.result.articles;
              }
            }
          },
          err => {
            // this.global.hideLoading();
            console.log("ERROR!: " + JSON.stringify(err));
            console.log("ERROR!: ", err);
            if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
              console.log("Server is down currently. Please try again after some time");
  
            }
            else
              if (err.status == "0") {
                console.log("Unable to connect to server due to connectivity issue");
              }
          }
        );
  
    }
    Edit(id) {
      this.router.navigate(["view-article"], { queryParams: { "_id": id } });
    }
    getitemperpage(ip) {
      this.ip = ip;
    }    
    showAlert(id) {
      let res = confirm("Are you sure want to delete this Article Category?");
      if (res) {
        this.delete(id);
      }
    }
    delete(id) {
      let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
      this.http
        .delete(this.global.apiUrl + this.global.DeleteArtical + id, { headers: headers })
        .subscribe(
          api_res => {
            let data = JSON.parse(JSON.stringify(api_res));
           // console.log(data)
            if (data.code == 200) {
             alert("Article deleted Sucessfully.");
              this.articallist();
            } else {
              alert("Something went wrong, please try again.");
              this.articallist();
            }
          },
          err => {
            //  this.global.hideLoading();
            console.log("ERROR!: ", err);
            console.log("ERROR!: ", err);
            if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
              console.log("Server is down currently. Please try again after some time");
            }
            else
              if (err.status == "0") {
                console.log("Unable to connect to server due to connectivity issue");
              }
          }
        );
    }
  
  }
  
