<div class="row">
    <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Profile</h4>
            <form class="forms-sample" [formGroup]="Form">
              <div class="form-group">
                <label for="exampleInputName1">Name</label>
                <input type="text" class="form-control" id="exampleInputName1" placeholder="Name"
                [(ngModel)]="name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                  <div *ngIf="f.name.errors.required">* Name is required</div>
              </div>
              </div>
              <div class="form-group">
                <label for="exampleInputEmail3">Email</label>
                <input type="email" class="form-control" id="exampleInputEmail3" placeholder="Email"
                [(ngModel)]="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">* Email is required</div>
              </div>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword4">Address</label>
                <textarea type="text" class="form-control" id="exampleInputPassword4" placeholder="Address"
                [(ngModel)]="address" formControlName="address" [ngClass]="{ 'is-invalid': submitted && f.address.errors }"></textarea>
                <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                  <div *ngIf="f.address.errors.required">* Address is required</div>
                </div>
              </div>
              <button type="submit" class="btn btn-primary mr-2" (click)="Update()">Save</button>
            </form>
          </div>
        </div>
    </div>
</div>
<div class="alert1" [class.visible]="isVisible">
  <h6 class="title">{{title}}</h6>
 </div>