import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../global';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-mainmaster',
  templateUrl: './mainmaster.component.html',
  styleUrls: ['./mainmaster.component.scss']
})
export class MainmasterComponent implements OnInit {
  userId ="";title=""; isVisible= false;
  name="";
  userRole="";
  sessiontoken="";
  constructor(private global: Globals, private cookieService: CookieService, private router: Router, private route: ActivatedRoute, private http: HttpClient) {
    this.userId = this.cookieService.get("userId");
    this.name = this.cookieService.get("Name");
    this.sessiontoken = this.cookieService.get("sessiontoken");
    this.userRole =this.cookieService.get("UserRole");
    if (this.userId != "") {
    }
    else {
      this.router.navigate([""]);
    }
  }

  ngOnInit(): void {
  }
  clickMethod() {
    if (confirm("Are you sure you want to logout?")) {
      this.chklogout();
    }
  }
  chklogout() {
    let headers = { "Content-Type": "application/json", "userId": this.cookieService.get("userId"), "authorization": this.cookieService.get("sessiontoken") };
    let data = {
      "userId": this.cookieService.get("userId"),
    }
    this.http
      .post(this.global.apiUrl + this.global.adminlogout, data, { headers: headers })
      .subscribe(
        api_res => {
          let data = JSON.parse(JSON.stringify(api_res));
          if (data.code == 200) {
            this.cookieService.delete('userId');
            this.cookieService.delete('sessiontoken');
            this.cookieService.delete('Mobile');
            this.cookieService.delete('Username');
            this.cookieService.delete('UserRole');
            this.cookieService.delete('Name');
            this.router.navigate([""]);
          }
          else {
          }
        },
        err => {
          console.log("ERROR!: " + err);
          console.log("ERROR!: ", err);
          if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
            // this.hideLoader();
            console.log("Server is down currently. Please try again after some time");

          }
          else
            if (err.status == "0") {
              // this.hideLoader();
              console.log("Unable to connect to server due to connectivity issue");

            }
            else
              if (err.status == "401") {
                alert("Session Expired , Login Again");
                this.cookieService.delete('userId');
                this.cookieService.delete('sessiontoken');
                this.cookieService.delete('Mobile');
                this.cookieService.delete('Username');
                this.cookieService.delete('UserRole');
                this.cookieService.delete('Name');
                this.cookieService.delete("sellerid");
                this.cookieService.delete("fromdate");
                this.cookieService.delete("todate");
                this.router.navigate([""]);
              }
        }
      );
  }
  
}
