<div class="row">
    <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title" *ngIf="!_id">Add Subscription Plan</h4>
            <h4 class="card-title" *ngIf="_id">Update Subscription Plan</h4>
            <form class="forms-sample" [formGroup]="Form">
             <div class="form-group">
                <label for="exampleInputName1">PlanName</label>
                <textarea type="text" class="form-control" id="exampleInputName1" placeholder="Plan Name"
                [(ngModel)]="planName" formControlName="planName" [ngClass]="{ 'is-invalid': submitted && f.planName.errors }"></textarea>
                <div *ngIf="submitted && f.planName.errors" class="invalid-feedback">
                  <div *ngIf="f.planName.errors.required">* Plan Name is required</div>
              </div>
              </div>
              <div class="form-group">
                <label for="exampleInputName1">Plan Details</label>
                <textarea type="text" class="form-control" id="exampleInputName1" placeholder="Description"
                [(ngModel)]="description" formControlName="description" [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                  <div *ngIf="f.description.errors.required">* Plan Details is required</div>
              </div>
              </div>
              <div class="form-group">
                <label for="exampleInputName1">No Of Days</label>
                <input type="Number" min="0" class="form-control" placeholder="No Of Days" name="noOfdays" [(ngModel)]="noOfdays"
                [ngClass]="{ 'is-invalid': submitted && f.noOfdays.errors }"
                formControlName="noOfdays">
                <div *ngIf="submitted && f.noOfdays.errors" class="invalid-feedback">
                  <div *ngIf="f.noOfdays.errors.required">* No Of Days is required</div>
              </div>
              </div>
              <div class="form-group">
                <label for="exampleInputName1">Amount in USD</label>
                <input type="number" min="0" class="form-control" id="exampleInputName1" placeholder="Amount"
                [(ngModel)]="amount" formControlName="amount" [ngClass]="{ 'is-invalid': submitted && f.amount.errors }">
                <div *ngIf="submitted && f.amount.errors" class="invalid-feedback">
                  <div *ngIf="f.amount.errors.required">* Amount is required</div>
              </div>
              </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Status</label>
            <div class="col-sm-2">
                <div class="form-check">
                    <label class="form-check-label">
                        <input type="radio" class="form-check-input" name="status" id="membershipRadios1"
                            value="true" [(ngModel)]="status" formControlName="status">
                        <i class="input-helper"></i>Active</label>
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-check">
                    <label class="form-check-label">
                        <input type="radio" class="form-check-input" name="status" id="membershipRadios2"
                            value="false" [(ngModel)]="status" formControlName="status">
                        <i class="input-helper"></i>Inactive</label>
                </div>
            </div>
        </div>
        <div class="mb-3">
          <div class="mb-3">
              <label for="file-input" class="btn btn-primary btn-sm">Upload Image</label>
              <input id="file-input" type="file" accept="image/*" (change)="selectFiles($event)"
                  style="display: none;" />
          </div>
          <div *ngIf="Image" class="img-128 mb-3">
              <div class="ratio ratio-1x1 align-items-center ">
                  <img src="{{Image_url}}">
              </div>
          </div>
      </div>
              <button type="submit" class="btn btn-primary mr-2 float-right" *ngIf="!_id" (click)="Add()">Save</button>
              <button type="submit" class="btn btn-primary mr-2 float-right" *ngIf="_id" (click)="Update()">Update</button>
            </form>
          </div>
        </div>
    </div>
</div>
