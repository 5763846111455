import { Component, OnInit, Renderer2, Inject } from '@angular/core';
  import { Router, ActivatedRoute } from '@angular/router';
  import { HttpClient } from '@angular/common/http';
  import { Globals } from '../global';
  import { DOCUMENT } from '@angular/common';
  import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-subcategory-list',
  templateUrl: './subcategory-list.component.html',
  styleUrls: ['./subcategory-list.component.scss']
})
export class SubcategoryListComponent implements OnInit {
  p = 1; ip = 10; searchText;
    Id = "";
    userId = "";
    sessiontoken = "";
    listarray = [];
    title = ""; isVisible = false;
    categoryarray:any=[];
    categoryId="";
    constructor(private cookieService: CookieService, private router: Router, private http: HttpClient, private route: ActivatedRoute, private global: Globals, private renderer2: Renderer2, @Inject(DOCUMENT) private _document) {
      this.userId = this.cookieService.get("userId");
      this.sessiontoken = this.cookieService.get("sessiontoken");
      if (this.userId != "") {
        this.subcategorylist();
        this.categorylist();
      }
    }
    ngOnInit() {
    }
    subcategorylist() {
      let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
      this.listarray = [];
      this.http
        .get(this.global.apiUrl + this.global.ArticalSubcategorylist + this.categoryId +"/", { headers: headers })
        .subscribe(
          api_res => {
            //  this.global.hideLoading();
            let data = JSON.parse(JSON.stringify(api_res));
           // console.log(data)
            if (data.code == 200) {
              if (data.result.subcategories.length > 0) {
                this.listarray = data.result.subcategories;
              }
            }
          },
          err => {
            // this.global.hideLoading();
            console.log("ERROR!: " + JSON.stringify(err));
            console.log("ERROR!: ", err);
            if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
              console.log("Server is down currently. Please try again after some time");
  
            }
            else
              if (err.status == "0") {
                console.log("Unable to connect to server due to connectivity issue");
              }
          }
        );
  
    }
    Edit(id) {
      this.router.navigate(["article-subcategory"], { queryParams: { "_id": id } });
    }
    getitemperpage(ip) {
      this.ip = ip;
    }
    
    showAlert(id) {
      let res = confirm("Are you sure want to delete this Artical Subcategory?");
      if (res) {
        this.delete(id);
      }
    }
    delete(id) {
      let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
      this.http
        .delete(this.global.apiUrl + this.global.DeleteArticalSubcategory + id, { headers: headers })
        .subscribe(
          api_res => {
            let data = JSON.parse(JSON.stringify(api_res));
           // console.log(data)
            if (data.code == 200) {
             alert("Artical Subcategory deleted Sucessfully.");
              this.subcategorylist();
            } else {
              alert("Something went wrong, please try again.");
              this.subcategorylist();
            }
          },
          err => {
            //  this.global.hideLoading();
            console.log("ERROR!: ", err);
            console.log("ERROR!: ", err);
            if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
              console.log("Server is down currently. Please try again after some time");
            }
            else
              if (err.status == "0") {
                console.log("Unable to connect to server due to connectivity issue");
              }
          }
        );
    }
    categorylist() {
      let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
      this.categoryarray = [];
      this.http
        .get(this.global.apiUrl + this.global.ArticalCategorylist, { headers: headers })
        .subscribe(
          api_res => {
            //  this.global.hideLoading();
            let data = JSON.parse(JSON.stringify(api_res));
            if (data.code == 200) {
              if (data.result.articalcategories.length > 0) {
                this.categoryarray = data.result.articalcategories;
              }
            }
          },
          err => {
            // this.global.hideLoading();
            console.log("ERROR!: " + JSON.stringify(err));
            console.log("ERROR!: ", err);
            if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
              console.log("Server is down currently. Please try again after some time");
  
            }
            else
              if (err.status == "0") {
                console.log("Unable to connect to server due to connectivity issue");
              }
          }
        );
  
    }
  }
  
  

  
  
  