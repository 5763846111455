import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../global';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.css']
})
export class AdminListComponent implements OnInit {
  adminArray = [];
  Id = "";
  userId = "";
  sessiontoken = "";
  inputstext = "";
  showaddbtn = false;
  isApproved;searchText="";
  show = false;ip=10;p=1;
  constructor(private cookieService: CookieService, private router: Router, private http: HttpClient, private route: ActivatedRoute, private global: Globals, private renderer2: Renderer2, @Inject(DOCUMENT) private _document) {
    this.Id = this.route.snapshot.queryParamMap.get("_id");
    this.userId = this.cookieService.get("userId");
      this.sessiontoken = this.cookieService.get("sessiontoken");
      if (this.userId != "") {
    this.getadminlist();
  }
}
  ngOnInit() {
    // this.loadScript();
    setTimeout(() => {
      this.showaddbtn = true;
    }, 1000);
  }

  loadScript() {
    this.show = true;
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.src = '../../assets/js/pages/tbl-datatable-custom1.js';
    s.text = ``;
    this.renderer2.appendChild(this._document.body, s);
  }

  loadjs() {
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.src = '../../assets/js/pages/load.js';
    s.text = ``;
    this.renderer2.appendChild(this._document.body, s);
  }

  Edit(id) {
    this.router.navigate(["add-admin"], { queryParams: { "_id": id } });
  }

  getitemperpage(ip) {
    this.ip = ip;
  }  
  getadminlist() {
    this.adminArray = [];
    this.show = false;
    this.http
      .get(this.global.apiUrl + this.global.adminlist)
      .subscribe(
        api_res => {
          let data = JSON.parse(JSON.stringify(api_res));
          if (data.code == 200) {
            if (data.result.admins.length > 0) {
              this.adminArray = data.result.admins;
                  this.loadScript();
              }
            }else{
              this.loadScript();
            }


        },
        err => {
          // this.global.hideLoading();
          this.loadScript();
          console.log("ERROR!: " + JSON.stringify(err));
          console.log("ERROR!: ", err);
          if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
            console.log("Server is down currently. Please try again after some time");

          }
          else
            if (err.status == "0") {
              console.log("Unable to connect to server due to connectivity issue");

            }
        }
      );

  }
  showAlert(id) {
    let res = confirm("Are you sure want to delete this Admin?");
    if (res) {
      this.deleteAdmin(id);
    }
  }

  deleteAdmin(id) {
    let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
    this.adminArray = [];
    this.http
      .delete(this.global.apiUrl + this.global.deleteAdmin + id,{ headers: headers })
      .subscribe(
        api_res => {
          let data = JSON.parse(JSON.stringify(api_res));
          if (data.code == 200) {
            if (data.message !== null) {
              alert(data.message);
              this.getadminlist();
            }
          } else {
            alert(data.resMessage);
          }
        },
        err => {

          if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {

            console.log("Server is down currently. Please try again after some time");

          }
          else
            if (err.status == "0") {

              console.log("Unable to connect to server due to connectivity issue");

            }

        }
      );
  }
}
  