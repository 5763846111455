
<div class="main-body">
    <div class="page-wrapper">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title" *ngIf="!_id">Add Admin</h5>
                        <h5 class="card-title" *ngIf="_id">Update Admin</h5>
                        <div class="row ">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Username</label>
                                    <input type="text" class="form-control " name="username" [(ngModel)]="username"
                                        placeholder="Enter Username" *ngIf="!_id">
                                    <input type="text" class="form-control " name="username" [(ngModel)]="username"
                                        placeholder="Enter Username" *ngIf="_id" readonly>
                                </div>
                                <div class="form-group">
                                    <label>Name</label>
                                    <input type="text" class="form-control " name="name" [(ngModel)]="name"
                                        placeholder="Enter Name">
                                </div>
                                <div class="form-group">
                                    <label>Mobile</label>
                                    <input title="Invalid Mobile Number" pattern="[789][0-9]{9}" id="userInput"
                                        maxlength="10" name="contact" [(ngModel)]="contact"
                                        (keypress)="numberOnly($event)" type="text" class="form-control" id="mobile"
                                        placeholder="Enter Mobile Number">
                                </div>
                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="email" title="Invalid email address"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" class="form-control"
                                        name="email" [(ngModel)]="email" aria-describedby="emailHelp"
                                        placeholder="Enter Email Address">
                                </div>
                                <div class="form-group">
                                    <label>Address</label>
                                    <textarea placeholder="Address" class="col-md-10" name="address"
                                        [(ngModel)]="address" class="form-control"></textarea>
                                </div>
                                <div class="form-group">
                                    <label>Password</label>
                                    <input type="password" class="form-control" name="password" [(ngModel)]="password"
                                        placeholder="Password">
                                </div>
                                <button type="submit" class="btn btn-primary float-right" *ngIf="!_id"
                                    (click)="checkusername()">Add Admin</button>
                                <button type="submit" class="btn btn-primary float-right" *ngIf="_id"
                                    (click)="update()">Update Admin</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>