<div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Query List</h4>
                <div class="table-responsive" *ngIf="arraylist.length>0">
                    <table class="table table-hover">
                      <thead>
                            <tr>
                                <th class="d-none"></th>
                                <th>Action</th>
                                <th>Name</th>
                                <th>Phone Number</th>
                                <th>Email</th>
                                <!--<th>Organisation</th>-->
                                <th>Query</th>
                                <th>Article Title</th>
                                <th>Query Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of arraylist | filter:searchText ">
                                <td class="d-none"></td>
                                <td><button type="button" class="btn btn-primary btn-rounded btn-icon"
                                (click)="view(item._id)"><i class="ti-eye"></i></button>
                            </td>
                                <td>{{item.name}}</td>
                                <td>{{item.mobile}}</td>
                                <td>{{item.email}}</td>
                                <!--<td>{{item.organisation}}</td>-->
                                <td>{{item.query}}</td>
                                <td *ngIf="!item.articleId"></td>
                                <td *ngIf="item.articleId">{{item.articleId.title}}</td>
                                <td>{{item.date | date:'dd/MM/yyyy hh:mm a'}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="pagination float-right mt-1" (pageChange)="p = $event">
                        </pagination-controls>
                    <div class="row mt-1">
                        <div class="col-lg-3 col-md-3 col-3">
                            <div class="form-group">
                                <select class="form-control form-control-sm" id="exampleFormControlSelect3"
                                    [(ngModel)]="ip" name="ip" (change)="getitemperpage(ip)">
                                    <option value="10">10</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center card-empty" *ngIf="arraylist.length==0">No Data Available</div>
            </div>
        </div>
    </div>
</div>