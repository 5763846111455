import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../global';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.scss']
})
export class SubscriptionPlanComponent implements OnInit {
  Image_url = "";
  selectedFiles?: FileList;
  userid = "";
  sessiontoken = "";
  categoryName = "";
  Form: FormGroup;
  submitted = false; _id = "";
  planName = ""; isVisible = false;
  description = "";
  noOfdays = "";
  amount = "";
  Image = ""; status = 'true';userId="";
  constructor(private formBuilder: FormBuilder, private cookieService: CookieService, private router: Router, private http: HttpClient, private route: ActivatedRoute, private renderer2: Renderer2, @Inject(DOCUMENT) private _document, private global: Globals) {
    this.userId = this.cookieService.get("userId");
    this.sessiontoken = this.cookieService.get("sessiontoken");
    this._id = this.route.snapshot.queryParamMap.get("_id");
    if (this.userId != "") {
      if (this._id == null || this._id == undefined) {
      } else {
        this.Details();
      }
    } else {
      this.router.navigate([""]);
    }
  }
  ngOnInit() {
    this.Form = this.formBuilder.group({
      planName: ['', Validators.required],
      description: ['', Validators.required],
      noOfdays: ['', Validators.required],
      amount: ['', Validators.required],
      Image: [''],
      status: ['', Validators.required],
    })
  }

  get f() { return this.Form.controls; }
  Update() {
    let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
    this.submitted = true;
    if (this.Form.invalid) {
      return;
    } else {
      let data = {
        id: this._id,
        planName: this.planName,
        description: this.description,
        noOfdays: this.noOfdays,
        amount: this.amount,
        Image: this.Image,
        status: this.status,
        modifiedby: this.userId
      };
      //console.log(this.global.apiUrl + this.global.faq, data, { headers: headers })
      this.http
        .put(this.global.apiUrl + this.global.Subscriptionplan, data, { headers: headers })
        .subscribe(
          api_res => {
            // this.global.hideLoading();
            let data = JSON.parse(JSON.stringify(api_res));
           // console.log(data)
            if (data.code == 200) {
              // this.hideLoader();
              if (data.result.subscription !== null || data.result.subscription !== undefined) {
                alert("Subscription Plan updated successfully.");
                this.router.navigate(['subscription-list']);
              } else {
                alert("Something went wrong, please try again.");
              }
            } else {
              alert("Something went wrong, please try again.");
            }
          },
          err => {
            console.log("ERROR!: ", err);
            console.log("ERROR!: ", err);
            if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
              // this.hideLoader();
              console.log("Server is down currently. Please try again after some time");

            }
            else
              if (err.status == "0") {
                // this.hideLoader();
                console.log("Unable to connect to server due to connectivity issue");

              }
          }
        );

    }

  }
  Add() {
    let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
    this.submitted = true;
    if (this.Form.invalid) {
      return;
    } else {
      let data = {
        planName: this.planName,
        description: this.description,
        noOfdays: this.noOfdays,
        amount: this.amount,
        Image: this.Image,
        createdby: this.userId,
        status: this.status,
      };
      //console.log(this.global.apiUrl + this.global.faq, data, { headers: headers })
      this.http
        .post(this.global.apiUrl + this.global.Subscriptionplan, data, { headers: headers })
        .subscribe(
          api_res => {
            // this.global.hideLoading();
            let data = JSON.parse(JSON.stringify(api_res));
           // console.log(data)
            if (data.code == 200) {
              if (data.result.subscription.planName == null || data.result.subscription.planName == undefined) {
                alert(data.result.subscription);
              } else {
                alert("Subscription Plan added successfully.");
                this.router.navigate(['subscription-list']);
              }
            } else {
              alert("Something went wrong, please try again.");
            }
          },
          err => {
            console.log("ERROR!: ", err);
            console.log("ERROR!: ", err);
            if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
              // this.hideLoader();
              console.log("Server is down currently. Please try again after some time");

            }
            else
              if (err.status == "0") {
                // this.hideLoader();
                console.log("Unable to connect to server due to connectivity issue");

              }
          }
        );
    }
  }
  Details() {
    let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
    this.http
      .get(this.global.apiUrl + this.global.SubscriptionPlandetails + this._id, { headers: headers })
      .subscribe(
        api_res => {
          // this.global.hideLoading();
          let data = JSON.parse(JSON.stringify(api_res));
         // console.log(data)
          if (data.code == 200) {
            if (data.result.subscription !== null || data.result.subscription !== undefined) {
              this.amount = data.result.subscription.amount;
              this.planName = data.result.subscription.planName;
              this.description = data.result.subscription.description;
              this.noOfdays = data.result.subscription.noOfdays;
              this.status = data.result.subscription.status.toString();
              this.Image_url = this.global.imageUrl1 + data.result.subscription.Image;
              this.Image = data.result.subscription.Image;
            } else {
              alert("Something went wrong, please try again.");
            }
          } else {
            alert("Something went wrong, please try again.");
          }
        },
        err => {
          console.log("ERROR!: ", err);
          console.log("ERROR!: ", err);
          if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
            // this.hideLoader();
            console.log("Server is down currently. Please try again after some time");
          }
          else
            if (err.status == "0") {
              // this.hideLoader();
              console.log("Unable to connect to server due to connectivity issue");
            }
        }
      );
  }
  selectFiles(event: any): void {
    this.selectedFiles = event.target.files;
    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          //  console.log(e.target.result);
          /// this.previews.push(e.target.result);
        };

        reader.readAsDataURL(this.selectedFiles[i]);
      }
      this.uploadFiles();
    }
  }
  uploadFiles(): void {
    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.upload(i, this.selectedFiles[i]);
      }
    }
  }
  upload(idx: number, file: File): void {
    // this.progressInfos[idx] = { value: 0, fileName: file.name };
    if (file) {
      const formData: FormData = new FormData();
      formData.append('file', file);
      this.http
        .post<any>(this.global.imageuploadUrl, formData, {
          reportProgress: true,
          responseType: 'json'
        }).subscribe(response => {
         // console.log(response)
          if (response.statusCode === 200) {
            this.Image = response.filename;
            this.Image_url = this.global.imageUrl1 + response.filename;
            //this.fileInputLabel = "";
          }
        }, er => {
          console.log(er);
          alert(er.error.error);
        });
    }
  }
}


