import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { Globals } from '../global';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-change-submitstatus',
  templateUrl: './change-submitstatus.component.html',
  styleUrls: ['./change-submitstatus.component.scss']
})
export class ChangeSubmitstatusComponent implements OnInit {

  status = "";
  id = "";
  userId = "";
  sessiontoken = "";
  remark="";
  topic="";
  constructor(private global: Globals, private cookieService: CookieService, private router: Router, private route: ActivatedRoute, private http: HttpClient, private renderer2: Renderer2, @Inject(DOCUMENT) private _document) {
    this.userId = this.cookieService.get("userId");
    this.sessiontoken = this.cookieService.get("sessiontoken");
    if(this.userId !==""){
      this.id = this.route.snapshot.queryParamMap.get("_id");
      if(this.userId !=="" ){
      this.getdetails();
      }
    }
  }

  ngOnInit() {
   
  }
  Update() {
    let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
    let data = {
      "id": this.id,
      "status": this.status,
      "remark" : this.remark
    };
    // this.global.showLoading();

    // // console.log(this.global.apiUrl + this.global.paymentstatus, data, { headers: headers })
    this.http
      .put(this.global.apiUrl + this.global.changeSubmitStatus, data, { headers: headers })
      .subscribe(
        api_res => {
          let data = JSON.parse(JSON.stringify(api_res));
          // this.hideLoader();
          if (data.code == 200) {
            // this.hideLoader();
            alert("Status Updated Sucessfully.");
            this.router.navigate(["submit-request-list"]);
          } else {
            alert("Something went wrong, please try again.");
            // this.hideLoader();
          }
        },
        err => {
          // this.hideLoader();
          console.log("ERROR!: ", err);
          console.log("ERROR!: ", err);
          if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
            console.log("Server is down currently. Please try again after some time");
          }
          else
            if (err.status == "0") {
              console.log("Unable to connect to server due to connectivity issue");

            }
        }
      );
  }
  getdetails() {
    let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
     //console.log(this.global.apiUrl + this.global.submitrequest+ this.id)
    this.http
      .get(this.global.apiUrl + this.global.submitrequest + this.id, { headers: headers })
      .subscribe(
        api_res => {
          let data = JSON.parse(JSON.stringify(api_res));
         // console.log(api_res);
          if (data.code == 200) {
            if (data.result.submitrequest != null) {
              this.topic = data.result.submitrequest.message;
              this.remark = data.result.submitrequest.remark;
              this.status = data.result.submitrequest.status;

            }
          }
        },
        err => {
          // this.global.hideLoading();
          console.log("ERROR!: " + JSON.stringify(err));
          console.log("ERROR!: ", err);
          if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
            console.log("Server is down currently. Please try again after some time");
          }
          else
            if (err.status == "0") {
              console.log("Unable to connect to server due to connectivity issue");
            }
        }
      );
  }

}



