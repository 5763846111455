<div class="row">
    <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title" *ngIf="!_id">Add Article Category</h4>
            <h4 class="card-title" *ngIf="_id">Update Article Category</h4>
            <form class="forms-sample" [formGroup]="Form">
              <div class="form-group">
                <label for="exampleInputName1">CategoryName</label>
                <input type="text" class="form-control" id="exampleInputName1" placeholder="Article Category"
                [(ngModel)]="categoryName" formControlName="categoryName" [ngClass]="{ 'is-invalid': submitted && f.categoryName.errors }">
                <div *ngIf="submitted && f.categoryName.errors" class="invalid-feedback">
                  <div *ngIf="f.categoryName.errors.required">* CategoryName is required</div>
              </div>
              </div>
              <button type="submit" class="btn btn-primary mr-2 float-right" *ngIf="!_id" (click)="Add()">Save</button>
              <button type="submit" class="btn btn-primary mr-2 float-right" *ngIf="_id" (click)="Update()">Update</button>
            </form>
          </div>
        </div>
    </div>
</div>
