import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmedValidator } from '../Confirmed.validator';
import { Globals } from '../global';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  Form: FormGroup;
  submitted= false;
  title="";isVisible = false;
  userId="";
  sessiontoken="";
  name="";email="";address="";
  constructor(private formBuilder: FormBuilder,private cookieService: CookieService, private router: Router, private http: HttpClient, private route: ActivatedRoute, private renderer2: Renderer2, @Inject(DOCUMENT) private _document, private global: Globals) {
    this.userId = this.cookieService.get("userId");
    this.sessiontoken = this.cookieService.get("sessiontoken");
    if (this.userId != "") {
      this.details();
    }else {
    }

  }
  ngOnInit() {
    this.Form = this.formBuilder.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      email: ['', Validators.required]
    })
  }
  
  get f() { return this.Form.controls; }
  Update() {
    let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
    this.submitted = true;
  if (this.Form.invalid) {
    return;
  }else {
      let data = {
        "name": this.name,
         "email": this.email, 
         "userId": this.userId,
         "address":this.address
      };
     // console.log(this.global.apiUrl + this.global.adminpin, data, { headers: headers })
      this.http
        .put(this.global.apiUrl + this.global.Updatedetails, data, { headers: headers })
        .subscribe(
          api_res => {
            // this.global.hideLoading();
            let data = JSON.parse(JSON.stringify(api_res));
           // console.log(data)
            if (data.code == 200) {
              // this.hideLoader();
              var title ="Profile updated successfully.";
              this.onOpenDialog(title);
            }
            else {
              var title ="Something went wrong, please try again.";
              this.OpenDialog(title);
            }
          },
          err => {
            console.log("ERROR!: ", err);
            console.log("ERROR!: ", err);
            if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
              // this.hideLoader();
              console.log("Server is down currently. Please try again after some time");

            }
            else
              if (err.status == "0") {
                // this.hideLoader();
                console.log("Unable to connect to server due to connectivity issue");

              }
          }
        );

    }

  }
  details() {
    let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
     
     // console.log(this.global.apiUrl + this.global.adminpin, data, { headers: headers })
      this.http
        .get(this.global.apiUrl + this.global.Admindetails+ this.userId, { headers: headers })
        .subscribe(
          api_res => {
            // this.global.hideLoading();
            let data = JSON.parse(JSON.stringify(api_res));
            //console.log(data)
            if (data.code == 200) {
              if(data.result.user!=null) {
              this.name =data.result.user.name;
               this.email=data.result.user.email;
               this.address=data.result.user.address;
            }

            }
            else {
              var title ="Something went wrong, please try again.";
              this.OpenDialog(title);
            }
          },
          err => {
            console.log("ERROR!: ", err);
            console.log("ERROR!: ", err);
            if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
              // this.hideLoader();
              console.log("Server is down currently. Please try again after some time");

            }
            else
              if (err.status == "0") {
                // this.hideLoader();
                console.log("Unable to connect to server due to connectivity issue");

              }
          }
        );

    }

  
  onOpenDialog(title) {
    this.title = title;
    if (this.isVisible) {
      return;
    }
    this.isVisible = true;
    setTimeout(()=> {
    this.isVisible = false;
    //this.router.navigate([""]);
    },1000);
    
  }
  OpenDialog(title) {
    this.title = title;
    if (this.isVisible) {
      return;
    }
    this.isVisible = true;
    setTimeout(()=> {
 this.isVisible = false;
    },1000);
    
  }

}








