import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../global';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import * as moment from "moment";
import { AppComponent } from '../app.component';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  p = 1; ip = 10; searchText;
  Id = "";
  userId = "";
  sessiontoken = "";
  inputstext = "";
  showaddbtn = false;
  listarray = []; showreport = false;
  showlist = false;
  name = "";
  isApproved; title = ""; isVisible = false;
  date = new Date();
  AllUser = 0;
  PaidUsers = 0;
  FreeUsers = 0;
  AllArticles = 0;
  FreeArticles = 0;
  PaidArticles = 0;
  todaysarticles = 0;
  lastweekarticles = 0;
  monthsarticles = 0;
  allarticles = 0;
  constructor(private cookieService: CookieService, private router: Router, private http: HttpClient, private route: ActivatedRoute, private global: Globals, private renderer2: Renderer2, @Inject(DOCUMENT) private _document) {
    this.userId = this.cookieService.get("userId");
    this.sessiontoken = this.cookieService.get("sessiontoken");
    this.name = this.cookieService.get("Name");
    if (this.userId != "") {
      this.count();
    }
  }
  ngOnInit(): void {
  }
  count() {
    let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
    this.http
      .get(this.global.apiUrl + this.global.DashboardCount, { headers: headers })
      .subscribe(
        api_res => {
          //  this.global.hideLoading();
          let data = JSON.parse(JSON.stringify(api_res));
          if (data.code == 200) {
            if (data.result != null || data.result != undefined) {
             this.AllUser = data.result.AllUser;
             this.PaidUsers= data.result.PaidUsers;
             this.FreeUsers= data.result.FreeUsers;
              this.AllArticles=data.result.AllArticles;
             this.FreeArticles= data.result.FreeArticles;
             this.PaidArticles= data.result.PaidArticles;
             this.todaysarticles= data.result.todaysarticles;
             this.lastweekarticles= data.result.lastweekarticles;
              this.monthsarticles= data.result.monthsarticles;
              this.allarticles= data.result.allarticles;
            }
          }
        },
        err => {
          // this.global.hideLoading();
          console.log("ERROR!: " + JSON.stringify(err));
          console.log("ERROR!: ", err);
          if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
            console.log("Server is down currently. Please try again after some time");

          }
          else
            if (err.status == "0") {
              console.log("Unable to connect to server due to connectivity issue");
            }
        }
      );

  }
  view(type) {
    this.router.navigate(["users-list"], { queryParams: { "type": type } });
  }
  View(type) {
    this.router.navigate(["article-list"], { queryParams: { "type": type } });
  }
}
