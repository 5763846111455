<div class="container-scroller">
  <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
    
      <h6 class="logo">QiNews</h6>
      <!--<a class="navbar-brand brand-logo mr-5" [routerLink]="'/dashboard'"><img
          src="https://apnaleadbazar.com/assets/img/logo.png" class="mr-2" alt="logo" /></a>
      <a class="navbar-brand brand-logo-mini" [routerLink]="'/dashboard'"><img
          src="https://apnaleadbazar.com/assets/img/logo.png" alt="logo" /></a>-->
    </div>
    <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
      <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
        <span class="icon-menu"></span>
      </button>
      <ul class="navbar-nav navbar-nav-right">
        <li class="nav-item nav-profile dropdown">
          <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
            <img src="assets/images/user.png" alt="profile" />
          </a>
          <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
            <a class="dropdown-item" [routerLink]="'/profile'">
              <i class="ti-user text-primary"></i>
              Profile
            </a>
            <a class="dropdown-item" [routerLink]="'/settings'">
              <i class="ti-settings text-primary"></i>
              Settings
            </a>
            <a class="dropdown-item" (click)="clickMethod()">
              <i class="ti-power-off text-primary"></i>
              Logout
            </a>
          </div>
        </li>
      </ul>
      <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
        data-toggle="offcanvas">
        <span class="icon-menu"></span>
      </button>
    </div>
  </nav>
  <div class="container-fluid page-body-wrapper">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/dashboard'">
            <i class="icon-grid menu-icon"></i>
            <span class="menu-title">Dashboard</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
            <i class="icon-box menu-icon"></i>
            <span class="menu-title">Manage Article Category</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="collapse" id="ui-basic">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item"> <a class="nav-link" [routerLink]="'/article-category-list'">Article Category List</a></li>
            </ul>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="collapse" href="#ui-basic4" aria-expanded="false" aria-controls="ui-basic">
            <i class="icon-box menu-icon"></i>
            <span class="menu-title">Manage Article Subcategory</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="collapse" id="ui-basic4">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item"> <a class="nav-link" [routerLink]="'/article-subcategory-list'">Article Subcategory List</a></li>
            </ul>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="collapse" href="#ui-basic1" aria-expanded="false" aria-controls="ui-basic">
            <i class="icon-box menu-icon"></i>
            <span class="menu-title">Manage Article</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="collapse" id="ui-basic1">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item"> <a class="nav-link" [routerLink]="'/article-list'">Article List</a></li>
              <li class="nav-item"> <a class="nav-link" [routerLink]="'/todays-viewed-articles'">Todays Viewed Articles</a></li>
              <li class="nav-item"> <a class="nav-link" [routerLink]="'/weeks-viewed-articles'">This Weeks Viewed Articles </a></li>
              <li class="nav-item"> <a class="nav-link" [routerLink]="'/months-viewed-articles'">This Months Viewed Articles</a></li>
              <li class="nav-item"> <a class="nav-link" [routerLink]="'/all-viewed-articles'">All Viewed Articles</a></li>
            
            </ul>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="collapse" href="#ui-basic2" aria-expanded="false" aria-controls="ui-basic">
            <i class="icon-box menu-icon"></i>
            <span class="menu-title">Manage Subscription Plan</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="collapse" id="ui-basic2">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item"> <a class="nav-link" [routerLink]="'/subscription-list'">Subscription Plan List</a></li>
            </ul>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/submit-request-list'">
            <i class="icon-box menu-icon"></i>
            <span class="menu-title">Submit Request List</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/users-list'">
            <i class="icon-box menu-icon"></i>
            <span class="menu-title">Users List</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/query-list'">
            <i class="icon-box menu-icon"></i>
            <span class="menu-title">Query List</span>
          </a>
        </li>

        <li class="nav-item">
          <a class="nav-link" data-toggle="collapse" href="#ui-admin" aria-expanded="false" aria-controls="ui-basic">
            <i class="icon-box menu-icon"></i>
            <span class="menu-title">Manage Admin</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="collapse" id="ui-admin">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item"> <a class="nav-link" [routerLink]="'/admin-list'">Admin List</a></li>
            </ul>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/configuration'">
            <i class="icon-box menu-icon"></i>
            <span class="menu-title">Configuration</span>
          </a>
        </li>
      </ul>
    </nav>
    <div class="main-panel">
      <div class="content-wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>