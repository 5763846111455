import { Component, OnInit, Renderer2, Inject } from '@angular/core';
  import { Router, ActivatedRoute } from '@angular/router';
  import { HttpClient } from '@angular/common/http';
  import { Globals } from '../global';
  import { DOCUMENT } from '@angular/common';
  import { CookieService } from 'ngx-cookie-service';
  import { Validators, FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-add-subcategory',
  templateUrl: './add-subcategory.component.html',
  styleUrls: ['./add-subcategory.component.scss']
})
export class AddSubcategoryComponent implements OnInit {
    userId = "";
    sessiontoken = "";
    subcategoryName = "";
    Form: FormGroup;
    submitted = false; _id = "";
    title = ""; isVisible = false;
    categoryId="";categoryarray:any=[];
    constructor(private formBuilder: FormBuilder, private cookieService: CookieService, private router: Router, private http: HttpClient, private route: ActivatedRoute, private renderer2: Renderer2, @Inject(DOCUMENT) private _document, private global: Globals) {
      this.userId = this.cookieService.get("userId");
      this.sessiontoken = this.cookieService.get("sessiontoken");
      this._id = this.route.snapshot.queryParamMap.get("_id");
      if (this.userId != "") {
        if (this._id == null || this._id == undefined) {
        } else {
          this.Details();
        }
        this.categorylist();
      } else {
        this.router.navigate([""]);
      }
    }
    ngOnInit() {
      this.Form = this.formBuilder.group({
        subcategoryName: ['', Validators.required],
        categoryId: ['', Validators.required],
      })
    }
  
    get f() { return this.Form.controls; }
    Update() {
      let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
      this.submitted = true;
      if (this.Form.invalid) {
        return;
      } else {
        let data = {
          "id": this._id,
          "subcategoryName": this.subcategoryName,
          "modifiedby": this.userId,
          categoryId: this.categoryId,
          status:true
        };
        //console.log(this.global.apiUrl + this.global.faq, data, { headers: headers })
        this.http
          .put(this.global.apiUrl + this.global.ArticalSubcategory, data, { headers: headers })
          .subscribe(
            api_res => {
              // this.global.hideLoading();
              let data = JSON.parse(JSON.stringify(api_res));
              //console.log(data)
              if (data.code == 200) {
                // this.hideLoader();
                if (data.result.subcategory !== null || data.result.subcategory !== undefined) {
                  alert("Article Category updated successfully.");
                  this.router.navigate(['article-subcategory-list']);
                } else {
                  alert("Something went wrong, please try again.");
                }
              } else {
                alert("Something went wrong, please try again.");
              }
            },
            err => {
              console.log("ERROR!: ", err);
              console.log("ERROR!: ", err);
              if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
                // this.hideLoader();
                console.log("Server is down currently. Please try again after some time");
  
              }
              else
                if (err.status == "0") {
                  // this.hideLoader();
                  console.log("Unable to connect to server due to connectivity issue");
  
                }
            }
          );
  
      }
  
    }
    Add() {
      let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
      this.submitted = true;
      if (this.Form.invalid) {
        return;
      } else {
        let data = {
          "subcategoryName": this.subcategoryName,
          "createdby": this.userId,
          categoryId: this.categoryId,
          status:true
        };
        //console.log(this.global.apiUrl + this.global.faq, data, { headers: headers })
        this.http
          .post(this.global.apiUrl + this.global.ArticalSubcategory, data, { headers: headers })
          .subscribe(
            api_res => {
              // this.global.hideLoading();
              let data = JSON.parse(JSON.stringify(api_res));
              ///console.log(data)
              if (data.code == 200) {
                if (data.result.subcategory.subcategoryName == null || data.result.subcategory.subcategoryName == undefined) {
                  alert(data.result.subcategory);
                }else{
                  alert("Article Subcategory added successfully.");
                  this.router.navigate(['article-subcategory-list']);
                 }
              } else {
                alert("Something went wrong, please try again.");
              }
            },
            err => {
              console.log("ERROR!: ", err);
              console.log("ERROR!: ", err);
              if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
                // this.hideLoader();
                console.log("Server is down currently. Please try again after some time");
  
              }
              else
                if (err.status == "0") {
                  // this.hideLoader();
                  console.log("Unable to connect to server due to connectivity issue");
  
                }
            }
          );
  
      }
  
    }
    Details() {
      let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
      this.http
        .get(this.global.apiUrl + this.global.ArticalSubcategoryDetails + this._id, { headers: headers })
        .subscribe(
          api_res => {
            // this.global.hideLoading();
            let data = JSON.parse(JSON.stringify(api_res));
            //console.log(data)
            if (data.code == 200) {
              if (data.result.subcategory !== null || data.result.subcategory !== undefined) {
                this.subcategoryName = data.result.subcategory.subcategoryName;
                this.categoryId = data.result.subcategory.categoryId._id;
              } else {
                alert("Something went wrong, please try again.");
              }
            } else {
              alert("Something went wrong, please try again.");
            }
          },
          err => {
            console.log("ERROR!: ", err);
            console.log("ERROR!: ", err);
            if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
              // this.hideLoader();
              console.log("Server is down currently. Please try again after some time");
            }
            else
              if (err.status == "0") {
                // this.hideLoader();
                console.log("Unable to connect to server due to connectivity issue");
              }
          }
        );
    }
    categorylist() {
      let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
      this.categoryarray = [];
      this.http
        .get(this.global.apiUrl + this.global.ArticalCategorylist, { headers: headers })
        .subscribe(
          api_res => {
            //  this.global.hideLoading();
            let data = JSON.parse(JSON.stringify(api_res));
            if (data.code == 200) {
              if (data.result.articalcategories.length > 0) {
                this.categoryarray = data.result.articalcategories;
              }
            }
          },
          err => {
            // this.global.hideLoading();
            console.log("ERROR!: " + JSON.stringify(err));
            console.log("ERROR!: ", err);
            if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
              console.log("Server is down currently. Please try again after some time");
  
            }
            else
              if (err.status == "0") {
                console.log("Unable to connect to server due to connectivity issue");
              }
          }
        );
  
    }
  }
  
  
