<div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth px-0">
        <div class="row w-100 mx-0">
          <div class="col-lg-4 mx-auto">
            <div class="auth-form-light text-left py-5 px-4 px-sm-5">
              <div class="brand-logo text-center">
                <img src="/assets/images/logo.png" alt="logo">
              </div>
              <div class="text-center">
                <h4>Admin</h4>
              </div>
              <!--<h4>Hello! let's get started</h4>-->
              <h6 class="font-weight-light">Sign in to continue.</h6>
              <form class="pt-3" [formGroup]="Form">
                <div class="form-group">
                  <input type="text" [(ngModel)]="username" class="form-control form-control-lg" id="exampleInputEmail1" placeholder="Username"
                  formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
                  <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required">* Username is required</div>
                </div>
                </div>
                <div class="form-group">
                  <input type="password" [(ngModel)]="pin" class="form-control form-control-lg" id="exampleInputPassword1" placeholder="Password"
                  formControlName="pin" [ngClass]="{ 'is-invalid': submitted && f.pin.errors }">
                  <div *ngIf="submitted && f.pin.errors" class="invalid-feedback">
                    <div *ngIf="f.pin.errors.required">* Password is required</div>
                </div>
                </div>
                <div class="mt-3">
                  <a type="submit" class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" (click)="Adminlogin()">SIGN IN</a>
                </div>
                <div class="my-2 d-flex justify-content-between align-items-center">
                  <!--<div class="form-check">
                    <label class="form-check-label text-muted">
                      <input type="checkbox" class="form-check-input">
                      Keep me signed in
                    </label>
                  </div>-->
                  <!--<a href="#" class="auth-link text-black">Forgot password?</a>-->
                </div>
                <!--<div class="mb-2">
                  <button type="button" class="btn btn-block btn-facebook auth-form-btn">
                    <i class="ti-facebook mr-2"></i>Connect using facebook
                  </button>
                </div>
                <div class="text-center mt-4 font-weight-light">
                  Don't have an account? <a href="register.html" class="text-primary">Create</a>
                </div>-->
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- content-wrapper ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
  
