<div class="row">
    <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Settings</h4>
            <form class="forms-sample" [formGroup]="Form">
              <div class="form-group">
                <label for="exampleInputName1">Current Password</label>
                <input type="text" class="form-control" id="exampleInputName1" placeholder="Current Password"
                [(ngModel)]="oldPwd" formControlName="oldPwd" [ngClass]="{ 'is-invalid': submitted && f.oldPwd.errors }">
                <div *ngIf="submitted && f.oldPwd.errors" class="invalid-feedback">
                  <div *ngIf="f.oldPwd.errors.required">* Current Password is required</div>
              </div>
              </div>
              <div class="form-group">
                <label for="exampleInputEmail3">New Password</label>
                <input type="email" class="form-control" id="exampleInputEmail3" placeholder="New Password"
                [(ngModel)]="newPwd" formControlName="newPwd" [ngClass]="{ 'is-invalid': submitted && f.newPwd.errors }">
                <div *ngIf="submitted && f.newPwd.errors" class="invalid-feedback">
                  <div *ngIf="f.newPwd.errors.required">* New Password is required</div>
              </div>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword4">Confirm Password</label>
                <input type="text" class="form-control" id="exampleInputPassword4" placeholder="Confirm Password"
                [(ngModel)]="CnewPwd" formControlName="CnewPwd" [ngClass]="{ 'is-invalid': submitted && f.CnewPwd.errors }">
                <div *ngIf="submitted && f.CnewPwd.errors" class="invalid-feedback">
                  <div *ngIf="f.CnewPwd.errors.required">* Confirm Password is required</div>
                  <div *ngIf="f.CnewPwd.errors.confirmedValidator">Password and Confirm Password must be match.</div>
                </div>
              </div>
              <button type="submit" class="btn btn-primary mr-2" (click)="UpdatePassword()">Change Password</button>
            </form>
          </div>
        </div>
    </div>
</div>
<div class="alert1" [class.visible]="isVisible">
  <h6 class="title">{{title}}</h6>
 </div>