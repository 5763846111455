import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../global';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-add-admin',
  templateUrl: './add-admin.component.html',
  styleUrls: ['./add-admin.component.css']
})
export class AddAdminComponent implements OnInit {
  _id = "";
  name = "";
  address = "";
  contact = "";
  email = "";
  password = "";
  userRole = "";
  chkuser = "";
  status = false;
  userid = "";
  SessionToken = "";
  username = "";
  constructor(private cookieService: CookieService, private router: Router, private http: HttpClient, private route: ActivatedRoute, private global: Globals, private renderer2: Renderer2, @Inject(DOCUMENT) private _document) {
    this.userid = this.cookieService.get("userid");
    this.SessionToken = this.cookieService.get("SessionToken");
    this._id = this.route.snapshot.queryParamMap.get("_id");
    if (this.userid !== "") {
      if (this._id !== null) {
        this.admindetails();
      }
    }
  }
  ngOnInit() {
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    else if (charCode.length == false) {
      alert("Please enter 10 digit valid mobile number");
    }

    return true;

  }
  add() {
    if (this.name == "" || this.name == null) {
      alert("Please enter Name");
    }
    else if (this.contact == "" || this.contact == null) {
      alert("Please enter Contact");
    }
    else if (this.email == "" || this.email == null) {
      alert("Please enter Email");
    }
    else if (this.password == "" || this.password == null) {
      alert("Please enter Password");
    }
    else if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) == false) {
      alert("Please entered valid email address");
    }
    else
      if (this.contact.length != 10) {
        alert("Please enter valid Mobile Number");
      }
      else {
        let data = {
          "name": this.name,
          "address": this.address,
          "mobile": this.contact,
          "email": this.email,
          "password": this.password,
          "username": this.username
        };
        this.http
          .post(this.global.apiUrl + this.global.Addadmin, data)
          .subscribe(
            api_res => {
              let data = JSON.parse(JSON.stringify(api_res));
              if (data.code == 200) {
                if (data.result.admin != null || data.result.admin != undefined) {
                  alert("Admin Added successfully.");
                  this.router.navigate(["admin-list"]);
                }
              }
              else {
                alert("Something went wrong, please try again.");
              }
            },
            err => {
              // this.global.hideLoading();   
              console.log("ERROR!: " + err);
              console.log("ERROR!: ", err);
              if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
                console.log("Server is down currently. Please try again after some time");

              }
              else
                if (err.status == "0") {
                  console.log("Unable to connect to server due to connectivity issue");

                }
            }
          );
      }
  }
  checkusername() {
    this.http
      .get(this.global.apiUrl + this.global.CheckUsername + this.username)
      .subscribe(
        api_res => {
          let data = JSON.parse(JSON.stringify(api_res));
          if (data.code == 200) {
            if (data.result != null || data.result != undefined) {
              if (data.result.UsernameAvailable == true) {
                this.add();
              } else if (data.result.UsernameAvailable == false) {
                alert("Username already used.")
              }
            }
          }
          else {
            alert("Something went wrong, please try again.");
          }
        },
        err => {
          // this.global.hideLoading();   
          console.log("ERROR!: " + err);
          console.log("ERROR!: ", err);
          if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
            console.log("Server is down currently. Please try again after some time");

          }
          else
            if (err.status == "0") {
              console.log("Unable to connect to server due to connectivity issue");

            }
        }
      );
  }

  update() {
    if (this.name == "" || this.name == null) {
      alert("Please enter Name");
    }
    else if (this.contact == "" || this.contact == null) {
      alert("Please enter Contact");
    }
    else if (this.email == "" || this.email == null) {
      alert("Please enter Email");
    }
    else if (this.password == "" || this.password == null) {
      alert("Please enter Password");
    }
    else if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) == false) {
      alert("Please entered valid email address");
    }
    else
      if (this.contact.length != 10) {
        alert("Please enter valid Mobile Number");
      }
      else {
        let data = {
          "id": this._id,
          "name": this.name,
          "address": this.address,
          "mobile": this.contact,
          "email": this.email,
          "password": this.password,
          "username": this.username
        };
        this.http
          .put(this.global.apiUrl + this.global.Addadmin, data)
          .subscribe(
            api_res => {
              let data = JSON.parse(JSON.stringify(api_res));
              if (data.code == 200) {
                if (data.result.admin != null || data.result.admin != undefined) {
                  alert("Admin updated successfully.");
                  this.router.navigate(["admin-list"]);
                }
              }
              else {
                alert("Something went wrong, please try again.");
              }
            },
            err => {
              // this.global.hideLoading();   
              console.log("ERROR!: " + err);
              console.log("ERROR!: ", err);
              if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
                console.log("Server is down currently. Please try again after some time");

              }
              else
                if (err.status == "0") {
                  console.log("Unable to connect to server due to connectivity issue");

                }
            }
          );
      }
  }
  admindetails() {
    this.http
      .get(this.global.apiUrl + this.global.admindetails + this._id)
      .subscribe(
        api_res => {
          let data = JSON.parse(JSON.stringify(api_res));
          if (data.code == 200) {
            if (data.result.admin != null || data.result.admin != undefined) {
              this.contact = data.result.admin.mobile;
              this.username = data.result.admin.username;
              this.email = data.result.admin.email;
              this.address = data.result.admin.address;
              this.password = data.result.admin.password;
              this.name = data.result.admin.name;
            }
          }
          else {
            alert("Something went wrong, please try again.");
          }
        },
        err => {
          // this.global.hideLoading();   
          console.log("ERROR!: " + err);
          console.log("ERROR!: ", err);
          if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
            console.log("Server is down currently. Please try again after some time");

          }
          else
            if (err.status == "0") {
              console.log("Unable to connect to server due to connectivity issue");

            }
        }
      );
  }
}
