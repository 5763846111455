import { Injectable } from '@angular/core';
Injectable()
export class Globals {
    
  //apiUrl = 'http://localhost:9007';

  apiUrl = "https://api.qimart.us:9007";
  ImageUrl = "https://api.qimart.us:9008";
  imageUrl = "https://api.qimart.us:9008/files/";
  imageUrl1 = 'https://api.qimart.us:9008/file/';
  imageuploadUrl = "https://api.qimart.us:9008/upload/";

  Updatedetails ="/v1/updateadmin";
  Admindetails="/v1/admindetails/";
 adminlogin="/v1/adminlogin";
 adminpin="/v1/adminpin";
 adminlogout="/v1/adminlogout";
 ArticalCategory ="/v1/articalcategory";
 ArticalCategorylist ="/v1/articalcategories";
 Artical ="/v1/artical";
 ArticalList = "/v1/getallarticals/";
 ArticalCategoryDetails= "/v1/articalcategory/";
 DeleteArticalCategory= "/v1/articalcategory/";
 DeleteArtical= "/v1/artical/";
 ArticalDetails= "/v1/artical/";
 Subscriptionplan="/v1/subscription";
 SubscriptionPlandetails ="/v1/subscription/";
 planlist="/v1/subscriptions";
 DeleteSubscriptionplan  ="/v1/subscription/";
 ArticalSubcategory = "/v1/subcategory";
 ArticalSubcategorylist ="/v1/subcategories/"
 ArticalSubcategoryDetails= "/v1/subcategory/";
 DeleteArticalSubcategory= "/v1/subcategory/";
 submitRequests="/v1/submitrequests/";
 submitrequest = "/v1/submitrequest/";
changeSubmitStatus="/v1/changerequeststatus";
DashboardCount = "/v1/dashboardcount";
userlist= "/v1/users/";
Todaysviewedarticles ="/v1/todaysviewedarticles";
Weeksviewedarticles = "/v1/weeksviewedarticles" ;
MonthsViewedArticles="/v1/monthsviewedarticles";
Allviewedarticles="/v1/allviewedarticles";
Querylist = "/v1/querylist";
getquery = "/v1/getquery/";

adminlist="/v1/adminlist";
deleteAdmin="/v1/admin/";
admindetails="/v1/admin/";
Addadmin="/v1/admin";
CheckUsername="/v1/checkadminusername/";

updateconfiguration = "/v1/updateconfiguration";
getconfiguration = "/v1/getconfiguration";
}

