import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { Globals } from '../global';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-submit-request-list',
  templateUrl: './submit-request-list.component.html',
  styleUrls: ['./submit-request-list.component.scss']
})
export class SubmitRequestListComponent implements OnInit {
  orderStatusid = "";
  searchText = "";
  userId = "";
  sessiontoken = "";
  requestarray = [];
  showaddbtn = false;
  p = 1; ip = 10;
  constructor(public rout: Router, private global: Globals, private cookieService: CookieService, private router: Router, private route: ActivatedRoute, private http: HttpClient, private renderer2: Renderer2, @Inject(DOCUMENT) private _document) {
    this.userId = this.cookieService.get("userId");
    this.sessiontoken = this.cookieService.get("sessiontoken");
    // alert(this.userid);
    if (this.userId != "") {
      this.loadScript1();
      this.getAllRequestslist();
      setTimeout(() => {
        this.showaddbtn = true;
      }, 500);
    }
    else {
      this.router.navigate([""]);
    }
  }

  ngOnInit() {
    // this.loadScript1();
  }

  allorders() {
    this.orderStatusid = "";
  }

  loadScript1() {
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.src = '../../assets/js/pages/tbl-datatable-custom1.js';
    s.text = ``;
    this.renderer2.appendChild(this._document.body, s);
  }
 
  viewrequest(id) {
    this.router.navigate(["view-submit-request"], { queryParams: { "_id": id } });
  }
  Change(id) {
    this.router.navigate(["change-submit-status"], { queryParams: { "_id": id } });
  }
  getitemperpage(ip) {
    this.ip = ip;
  }
  getAllRequestslist(){
    let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
    this.requestarray = [];
     this.http
      .get(this.global.apiUrl + this.global.submitRequests , { headers: headers })
      .subscribe(
        api_res => {
          let data = JSON.parse(JSON.stringify(api_res));
          //console.log(data);
          if (data.code == 200) {
           var name="", mobile ;
            this.requestarray = [];
            if (data.result.submitedrequests!= null && data.result.submitedrequests.length > 0) {
             for(let i=0; i<data.result.submitedrequests.length;i++){
               if(data.result.submitedrequests[i].userId!==null && data.result.submitedrequests[i].userId!==undefined){
                 name = data.result.submitedrequests[i].userId.firstName + " " + data.result.submitedrequests[i].userId.lastName ;
                 mobile =data.result.submitedrequests[i].userId.mobile;
                }else{
                  name = data.result.submitedrequests[i].name;
                  mobile =data.result.submitedrequests[i].mobile;
               }
             let json= {
              "topic":data.result.submitedrequests[i].topic,
              "subject": data.result.submitedrequests[i].subject,
              "message" :  data.result.submitedrequests[i].message.length >50?data.result.submitedrequests[i].message.slice(0, 50) +"...": data.result.submitedrequests[i].message,
              "_id" : data.result.submitedrequests[i]._id,
              "name" : name,
              "mobile":mobile,
              "status":data.result.submitedrequests[i].status,
              "date":data.result.submitedrequests[i].createdTime
             }
              this.requestarray.push(json);
            }
          }
          }
        },
        err => {
          // this.global.hideLoading();
          console.log("ERROR!: " + JSON.stringify(err));
          console.log("ERROR!: ", err);
          if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
            console.log("Server is down currently. Please try again after some time");

          }
          else
            if (err.status == "0") {
              console.log("Unable to connect to server due to connectivity issue");

            }
        }
      );
  }
}
