
<div class="row">
    <div class="col-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Change Status</h4>
                <form class="forms-sample">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Topic</label>
                        <textarea readonly [(ngModel)]="topic" name="topic" type="text" class="form-control" placeholder="Topic"></textarea>
                    </div>
<!--<div class="form-group">
                        <label for="exampleInputEmail1">Remark</label>
                        <input placeholder="Remark" class="browser-default custom-select mb-2" [(ngModel)]="remark" name="remark"
                            class="form-control">
                    </div>-->
                    <div class="form-group">
                        <label for="exampleInputEmail1">Status</label>
                        <select placeholder="status" class="browser-default custom-select mb-2" [(ngModel)]="status" name="status"
                            class="form-control">
                            <option value="" disabled>Select Status</option>
                            <option value="Open" disabled>Open</option>
                            <option value="Close">Close</option>
                        </select>
                    </div>
                    <button type="submit" class="btn btn-primary mr-2 float-right" (click)="Update()">Update</button>
                </form>
            </div>
        </div>
    </div>
</div>