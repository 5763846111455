import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Globals } from '../global';

@Component({
  selector: 'app-view-query',
  templateUrl: './view-query.component.html',
  styleUrls: ['./view-query.component.scss']
})
export class ViewQueryComponent implements OnInit {
  userId ="";message="";
  sessiontoken="";title="";
  id="";name="";mobile="";email="";
  constructor(private global: Globals, private cookieService: CookieService, private router: Router, private route: ActivatedRoute, private http: HttpClient, private renderer2: Renderer2, @Inject(DOCUMENT) private _document) {
    this.userId = this.cookieService.get("userId");
    this.sessiontoken = this.cookieService.get("sessiontoken");
    if(this.userId !==""){
      this.id = this.route.snapshot.queryParamMap.get("_id");
      this.getdetails();
    }
  }

  ngOnInit() {
   
  }
  getdetails() {
    let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
     console.log(this.global.apiUrl + this.global.getquery+ this.id)
    this.http
      .get(this.global.apiUrl + this.global.getquery + this.id, { headers: headers })
      .subscribe(
        api_res => {
          let data = JSON.parse(JSON.stringify(api_res));
         // console.log(api_res);
          if (data.code == 200) {
            if (data.result.query != null) {
              this.message = data.result.query.query;
              this.title = data.result.query.articleId.title;
              this.name = data.result.query.name;
              this.mobile = data.result.query.mobile;
              this.email = data.result.query.email;

            }
          }
        },
        err => {
          // this.global.hideLoading();
          console.log("ERROR!: " + JSON.stringify(err));
          console.log("ERROR!: ", err);
          if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
            console.log("Server is down currently. Please try again after some time");
          }
          else
            if (err.status == "0") {
              console.log("Unable to connect to server due to connectivity issue");
            }
        }
      );
  }


}
