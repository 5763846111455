<div class="row">
    <div class="col-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title" *ngIf="!_id">Add Article</h4>
                <h4 class="card-title" *ngIf="_id">Update Article</h4>
                <form class="forms-sample" [formGroup]="Form">
                    <div class="form-group">
                        <label for="exampleInputName1">Title</label>
                        <input type="text" class="form-control" id="exampleInputName1" placeholder="Title"
                            [(ngModel)]="title" formControlName="title"
                            [ngClass]="{ 'is-invalid': submitted && f.title.errors }">
                        <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                            <div *ngIf="f.title.errors.required">* Title is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputName1">Description</label>
                        <textarea type="text" class="form-control" id="exampleInputName1" placeholder="Description"
                            [(ngModel)]="description" formControlName="description"
                            [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                        <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                            <div *ngIf="f.description.errors.required">* Description is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputName1">Full Article</label>
                        <angular-editor placeholder="Full Article" [config]="editorConfig" name="fullartical"
                            [(ngModel)]="fullartical" [ngClass]="{ 'is-invalid': submitted && f.fullartical.errors }"
                            formControlName="fullartical"></angular-editor>
                        <div *ngIf="submitted && f.fullartical.errors" class="invalid-feedback">
                            <div *ngIf="f.fullartical.errors.required">* Full Article is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputName1">Category</label>
                        <select type="text" class="form-control" id="exampleInputName1" placeholder="Category"
                            [(ngModel)]="categoryId" formControlName="categoryId" (change)="subcategorylist(categoryId)"
                            [ngClass]="{ 'is-invalid': submitted && f.categoryId.errors }">
                            <option value="" disabled>Select Category</option>
                            <option *ngFor="let item of categoryarray" [value]="item._id">{{item.categoryName}}</option>
                        </select>
                        <div *ngIf="submitted && f.categoryId.errors" class="invalid-feedback">
                            <div *ngIf="f.categoryId.errors.required">* Category is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                            <label for="exampleInputName1">Subcategory</label>
                            <select type="text" class="form-control" id="exampleInputName1" placeholder="Subcategory"
                                [(ngModel)]="subcategoryId" formControlName="subcategoryId" name="subcategoryId"
                                [ngClass]="{ 'is-invalid': submitted && f.subcategoryId.errors }">
                                <option value="" disabled>Select Subcategory</option>
                                <option *ngFor="let item of subcategoryarray" [value]="item._id">{{item.subcategoryName}}</option>
                            </select>
                            <div *ngIf="submitted && f.subcategoryId.errors" class="invalid-feedback">
                                <div *ngIf="f.subcategoryId.errors.required">* Subcategory is required</div>
                            </div>
                        </div>
                    <div class="form-group">
                        <label>Closed Date</label>
                        <ejs-datepicker id='date1' [(ngModel)]="closedDate"
                            [ngClass]="{ 'is-invalid': submitted && f.closedDate.errors }" formControlName="closedDate"
                            style="padding-left:10px; height:45px" name="closedDate" class="form-control"
                            [min]='minDate'>
                        </ejs-datepicker>
                        <div *ngIf="submitted && f.closedDate.errors" class="invalid-feedback">
                            <div *ngIf="f.closedDate.errors.required">* Start Date is required</div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Article Type</label>
                        <div class="col-sm-2">
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="articalType"
                                        id="membershipRadios1" value="Free" [(ngModel)]="articalType"
                                        formControlName="articalType">
                                    <i class="input-helper"></i>
                                    Free
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="articalType"
                                        id="membershipRadios2" value="Paid" [(ngModel)]="articalType"
                                        formControlName="articalType">
                                    <i class="input-helper"></i>
                                    Paid
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Show In Ticker</label>
                        <div class="col-sm-2">
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="showinticker"
                                        id="membershipRadios1" value="true" [(ngModel)]="showinticker"
                                        formControlName="showinticker">
                                    <i class="input-helper"></i>Yes</label>
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="showinticker"
                                        id="membershipRadios2" value="false" [(ngModel)]="showinticker"
                                        formControlName="showinticker">
                                    <i class="input-helper"></i>No</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Show On Banner</label>
                            <div class="col-sm-2">
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="showonBanner"
                                            id="membershipRadios1" value="true" [(ngModel)]="showonBanner"
                                            formControlName="showonBanner">
                                        <i class="input-helper"></i>Yes</label>
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="showonBanner"
                                            id="membershipRadios2" value="false" [(ngModel)]="showonBanner"
                                            formControlName="showonBanner">
                                        <i class="input-helper"></i>No</label>
                                </div>
                            </div>
                    </div>
                    <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Show On Homepage</label>
                            <div class="col-sm-2">
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="showonHomepage"
                                            id="membershipRadios1" value="true" [(ngModel)]="showonHomepage"
                                            formControlName="showonHomepage">
                                        <i class="input-helper"></i>Yes</label>
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="showonHomepage"
                                            id="membershipRadios2" value="false" [(ngModel)]="showonHomepage"
                                            formControlName="showonHomepage">
                                        <i class="input-helper"></i>No</label>
                                </div>
                            </div>
                    </div>
                    <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Latest News</label>
                            <div class="col-sm-2">
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="latestNews"
                                            id="membershipRadios1" value="true" [(ngModel)]="latestNews"
                                            formControlName="latestNews">
                                        <i class="input-helper"></i>Yes</label>
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="latestNews"
                                            id="membershipRadios2" value="false" [(ngModel)]="latestNews"
                                            formControlName="latestNews">
                                        <i class="input-helper"></i>No</label>
                                </div>
                            </div>
                    </div>
                    <div class="form-group">
                            <label for="exampleInputName1">Tags</label>
                            <input type="text" class="form-control" id="exampleInputName1" placeholder="Tags"
                                [(ngModel)]="tags" formControlName="tags" name="tags"
                                [ngClass]="{ 'is-invalid': submitted && f.tags.errors }">
                            <div *ngIf="submitted && f.tags.errors" class="invalid-feedback">
                                <div *ngIf="f.tags.errors.required">* Tags is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputName1">Type</label>
                            <select type="text" class="form-control" id="exampleInputName1" placeholder="Type"
                                [(ngModel)]="type" formControlName="type" name="type" (change)="gettype(type)">
                                <option value="Image">Image</option>
                                <option value="Video">video</option>
                                </select>
                        </div>
                    <div class="mb-3" *ngIf="type=='Image'">
                        <div class="mb-3">
                            <label for="file-input" class="btn btn-primary btn-sm">Upload Image</label>
                            <input id="file-input" type="file" accept="image/png, image/jpg, image/jpeg" (change)="selectFiles($event)"
                                style="display: none;" />
                        </div>
                        <div *ngIf="Image" class="img-128 mb-3">
                            <div class="ratio ratio-1x1 align-items-center ">
                                <img src="{{Image_url}}" style="width:30%">
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="type=='Video'">
                        <label for="exampleInputName1">Videolink</label>
                        <input type="text" class="form-control" id="exampleInputName1" placeholder="Videolink"
                            [(ngModel)]="videolink" formControlName="videolink" name="videolink">
                    </div>
                    <div class="card rounded mb-3" *ngIf="videolink">
                        <div class="row g-2 align-items-center">
                            <div class="col-6">
                                <div class="ratio ratio-16x9 bg-warning align-items-center pt-0 h-100">
                                    <iframe [src]="Videolink" class="rounded" title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen></iframe>
                                    <!--<iframe src="https://www.youtube.com/embed/6fWU0e6W8QY?controls=0" class="rounded" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary mr-2 float-right" *ngIf="!_id"
                        (click)="Add()">Save</button>
                    <button type="submit" class="btn btn-primary mr-2 float-right" *ngIf="_id"
                        (click)="Update()">Update</button>
                </form>
            </div>
        </div>
    </div>
</div>