<div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Users List</h4>
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-4">
                        <div class="form-group">
                            <select type="text" class="form-control" id="exampleInputName1"
                                [(ngModel)]="type" name="type" (change)="gettype(type)">
                                <option value="All">All Users</option>
                                <option value="Paid">Paid Users</option>
                                <option value="Free">Free Users</option>
                            </select>
                           
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-4" *ngIf="arraylist.length>0">
                        <div class="form-group">
                            <input type="text" class="form-control" [(ngModel)]="searchText" name="searchText"
                                placeholder="Search">
                        </div>
                    </div>
                </div>
                <div class="table-responsive" *ngIf="arraylist.length>0">
                    <table class="table table-hover">
                      <thead>
                            <tr>
                                <th class="d-none"></th>
                                <th>Name</th>
                                <th>Phone Number</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of arraylist | filter:searchText ">
                                <td class="d-none"></td>
                                <td>{{item.name}}</td>
                                <td>{{item.mobile}}</td>
                                <td>{{item.email}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="pagination float-right mt-1" (pageChange)="p = $event">
                        </pagination-controls>
                    <div class="row mt-1">
                        <div class="col-lg-3 col-md-3 col-3">
                            <div class="form-group">
                                <select class="form-control form-control-sm" id="exampleFormControlSelect3"
                                    [(ngModel)]="ip" name="ip" (change)="getitemperpage(ip)">
                                    <option value="10">10</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center card-empty" *ngIf="arraylist.length==0">No Data Available</div>
            </div>
        </div>
    </div>
</div>