import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../global';
import { CookieService } from 'ngx-cookie-service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username = "";
  pin = "";
  token = "";
  Form: FormGroup;
  submitted= false;
  constructor(private formBuilder: FormBuilder,private renderer2: Renderer2, @Inject(DOCUMENT) private _document,private http: HttpClient, private global: Globals, private router: Router, private cookieService: CookieService) { }
  ngOnInit() {
    this.Form = this.formBuilder.group({
      username: ['', Validators.required],
      pin: ['', Validators.required]
    })
  }
  get f() { return this.Form.controls; }
  
  Adminlogin() {
    this.submitted = true;
    if (this.Form.invalid) {
      return;
    } else {
      let data = {
        "username": this.username,
        "pin": this.pin
      };
       console.log(this.global.apiUrl + this.global.adminlogin, data);
      this.http
        .post(this.global.apiUrl + this.global.adminlogin, data)
        .subscribe(
          api_res => {
            let data = JSON.parse(JSON.stringify(api_res));
           // console.log(data)
            if (data.code == 200) {
              if (data.result.user._id != null && data.result.user._id != "") {
                this.cookieService.set("userId", data.result.user._id,1);
                this.cookieService.set("Username", data.result.user.username, 1);
                 this.cookieService.set("Mobile", data.result.user.mobile);
                this.cookieService.set("UserRole", data.result.user.userRole,1);
                this.cookieService.set("Name", data.result.user.name,1);
                this.cookieService.set("sessiontoken", data.result.token,1);
                this.router.navigate(['dashboard']).then(()=>{
                  window.location.reload();
                });             
              } else {
                this.router.navigate([""]);
              }
            }
            else if (data.code == 720 || data.code == 703) {
             alert("Invalid credentials. Please retry again.");
            }
            else {
              alert(data.message);
            }
          },
          err => {
            console.log("ERROR!: " + JSON.stringify(err));
            console.log("ERROR!: ", err);
            if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
              console.log("Server is down currently. Please try again after some time");
            }
            else
              if (err.status == "0") {
                console.log("Unable to connect to server due to connectivity issue");
              }
          }
        );
    }
  }
  
}
