<div class="row">
    <div class="col-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Configuration</h4>
                <form>

                    <div class="form-group">
                        <label for="exampleInputName1">Terms And Conditions</label>
                        <angular-editor placeholder="Terms And Conditions" [config]="editorConfig" name="TermsAndConditions"
                            [(ngModel)]="TermsAndConditions"></angular-editor>
                    </div>
                    <div class="form-group">
                        <label>Careers</label>
                        <angular-editor [config]="editorConfig1" placeholder="Careers" name="career" [(ngModel)]="career"></angular-editor>
                    </div>
                    <form></form>
                    <button (click)="update()" type="submit"
                        class="btn btn-primary float-right">Update</button>
                  </form>
            
            </div>
        </div>
    </div>
</div>