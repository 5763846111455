import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { MainmasterComponent } from './mainmaster/mainmaster.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AddArticalCategoryComponent } from './add-artical-category/add-artical-category.component';
import { AddArticalComponent } from './add-artical/add-artical.component';
import { ArticalCategoryListComponent } from './artical-category-list/artical-category-list.component';
import { ArticalListComponent } from './artical-list/artical-list.component';
import { SettingComponent } from './setting/setting.component';
import { SubscriptionPlansComponent } from './subscription-plans/subscription-plans.component';
import { SubscriptionPlanComponent } from './subscription-plan/subscription-plan.component';
import { SubcategoryListComponent } from './subcategory-list/subcategory-list.component';
import { AddSubcategoryComponent } from './add-subcategory/add-subcategory.component';
import { SubmitRequestListComponent } from './submit-request-list/submit-request-list.component';
import { ChangeSubmitstatusComponent } from './change-submitstatus/change-submitstatus.component';
import { UsersListComponent } from './users-list/users-list.component';
import { TodaysArticlesComponent } from './todays-articles/todays-articles.component';
import { WeekArticlesComponent } from './week-articles/week-articles.component';
import { MonthsArticlesComponent } from './months-articles/months-articles.component';
import { AllViewedArticlesComponent } from './all-viewed-articles/all-viewed-articles.component';
import { ViewArticleComponent } from './view-article/view-article.component';
import { QueryListComponent } from './query-list/query-list.component';
import { ProfileComponent } from './profile/profile.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { ViewQueryComponent } from './view-query/view-query.component';
import { AddAdminComponent } from './add-admin/add-admin.component';
import { AdminListComponent } from './admin-list/admin-list.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  {
    path: '', component: MainmasterComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'article-category', component: AddArticalCategoryComponent },
      { path: 'article-category-list', component: ArticalCategoryListComponent },
      { path: 'article', component: AddArticalComponent },
      { path: 'article-list', component: ArticalListComponent },
      { path: 'settings', component: SettingComponent },
      { path: 'subscription', component: SubscriptionPlanComponent},
      { path: 'subscription-list', component: SubscriptionPlansComponent},
      { path: 'article-subcategory', component: AddSubcategoryComponent },
      { path: 'article-subcategory-list', component: SubcategoryListComponent },
      { path: 'submit-request-list', component: SubmitRequestListComponent },
      { path: 'change-submit-status', component: ChangeSubmitstatusComponent },    
      { path: 'users-list', component: UsersListComponent },
      { path: 'todays-viewed-articles', component: TodaysArticlesComponent },
      { path: 'weeks-viewed-articles', component: WeekArticlesComponent },
      { path: 'months-viewed-articles', component: MonthsArticlesComponent },
      { path: 'all-viewed-articles', component: AllViewedArticlesComponent },
      { path: 'view-article', component: ViewArticleComponent },
      { path: 'query-list', component: QueryListComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'configuration', component: ConfigurationComponent },
      { path: 'view-query', component: ViewQueryComponent },
      { path : "admin-list", component : AdminListComponent },
      { path : "add-admin", component : AddAdminComponent },
      
    
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true , scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
