import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Globals } from '../global';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {
  TermsAndConditions="";
  career ="";
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };
  editorConfig1: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };
  userId="";
  sessiontoken="";
  constructor(private cookieService: CookieService, private router: Router, private http: HttpClient, private route: ActivatedRoute, private global: Globals, private renderer2: Renderer2, @Inject(DOCUMENT) private _document) {
    this.userId = this.cookieService.get("userId");
    this.sessiontoken = this.cookieService.get("sessiontoken");
    this.details();
    }

  ngOnInit(): void {
  }
  details() {
    let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
    this.http
      .get(this.global.apiUrl + this.global.getconfiguration, { headers: headers})
      .subscribe(
        api_res => {
          let data = JSON.parse(JSON.stringify(api_res));
          //console.log(data)
          if (data.code == 200) {
            if (data.result != null || data.result != undefined) {
              this.TermsAndConditions = data.result.TermsAndConditions;
              this.career = data.result.career;
            }
          }
          else {
            alert("Something went wrong, please try again.");
          }
        },
        err => {
          // this.global.hideLoading();   
          console.log("ERROR!: " + err);
          console.log("ERROR!: ", err);
          if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
            console.log("Server is down currently. Please try again after some time");

          }
          else
            if (err.status == "0") {
              console.log("Unable to connect to server due to connectivity issue");

            }
        }
      );
  }
  update() {
    let headers = { "Content-Type": "application/json", "userid": this.userId, "authorization": this.sessiontoken };
    let data = {
      TermsAndConditions : this.TermsAndConditions,
      career: this.career
    }
    this.http
      .put(this.global.apiUrl + this.global.updateconfiguration, data, { headers: headers})
      .subscribe(
        api_res => {
          let data = JSON.parse(JSON.stringify(api_res));
          if (data.code == 200) {
            if (data.result != null || data.result != undefined) {
              alert("Configuration updated successfully")
            }
          }
          else {
            alert("Something went wrong, please try again.");
          }
        },
        err => {
          // this.global.hideLoading();   
          console.log("ERROR!: " + err);
          console.log("ERROR!: ", err);
          if (err.status == "500" || err.status == "501" || err.status == "502" || err.status == "503" || err.status == "504") {
            console.log("Server is down currently. Please try again after some time");

          }
          else
            if (err.status == "0") {
              console.log("Unable to connect to server due to connectivity issue");

            }
        }
      );
  }
}
