<div class="row">
    <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title" *ngIf="!_id">Add Article Subcategory</h4>
            <h4 class="card-title" *ngIf="_id">Update Article Subcategory</h4>
            <form class="forms-sample" [formGroup]="Form">
              <div class="form-group">
                <label for="exampleInputName1">SubcategoryName</label>
                <input type="text" class="form-control" id="exampleInputName1" placeholder="Article Subcategory Name"
                [(ngModel)]="subcategoryName" formControlName="subcategoryName" [ngClass]="{ 'is-invalid': submitted && f.subcategoryName.errors }">
                <div *ngIf="submitted && f.subcategoryName.errors" class="invalid-feedback">
                  <div *ngIf="f.subcategoryName.errors.required">* SubcategoryName is required</div>
              </div>
              </div>
              <div class="form-group">
                <label for="exampleInputName1">Category</label>
                <select type="text" class="form-control" id="exampleInputName1" placeholder="Category"
                [(ngModel)]="categoryId" formControlName="categoryId" [ngClass]="{ 'is-invalid': submitted && f.categoryId.errors }">
                <option value="" disabled>Select Category</option>
                <option *ngFor="let item of categoryarray" [value]="item._id">{{item.categoryName}}</option>
            </select>
                <div *ngIf="submitted && f.categoryId.errors" class="invalid-feedback">
                  <div *ngIf="f.categoryId.errors.required">* Category is required</div>
              </div>
              </div>
              <button type="submit" class="btn btn-primary mr-2 float-right" *ngIf="!_id" (click)="Add()">Save</button>
              <button type="submit" class="btn btn-primary mr-2 float-right" *ngIf="_id" (click)="Update()">Update</button>
            </form>
          </div>
        </div>
    </div>
</div>
