<div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Submit Request List</h4>
                <div class="row" *ngIf="requestarray.length>0">
                    <div class="col-lg-6 col-md-6 col-6">
                        <div class="form-group">
                            <input type="text" class="form-control" [(ngModel)]="searchText" name="searchText"
                                placeholder="Search">
                        </div>
                    </div>
                </div>
                <div class="table-responsive" *ngIf="requestarray.length>0">
                    <table class="table table-hover">
                      <thead>
                            <tr>
                                <th class="d-none"></th>
                                <th class="text-center">Action</th>
                                <th>Message</th>
                                <th>Requested By</th>
                                <th>Requested Date</th>
                                <th>Phone Number</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of requestarray | filter:searchText ">
                                <td class="d-none"></td>
                                <td>
                                    <!--<button type="button" class="btn btn-primary btn-rounded btn-icon"
                                    (click)="viewrequest(item._id)"><i class="ti-eye"></i></button>&nbsp;-->
                                    <button type="button" class="btn btn-primary btn-rounded btn-icon"
                                    (click)="Change(item._id)"><i class="ti-pencil"></i></button>
                                </td>
                                <td>{{item.message}}</td>
                                <td>{{item.name}}</td>
                                <td>{{item.date|date:'dd/MM/yyyy hh:mm a'}}</td>
                                <td>{{item.mobile}}</td>
                                <td>{{item.status}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="pagination float-right mt-1" (pageChange)="p = $event">
                        </pagination-controls>
                    <div class="row mt-1">
                        <div class="col-lg-3 col-md-3 col-3">
                            <div class="form-group">
                                <select class="form-control form-control-sm" id="exampleFormControlSelect3"
                                    [(ngModel)]="ip" name="ip" (change)="getitemperpage(ip)">
                                    <option value="10">10</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center card-empty" *ngIf="requestarray.length==0">No Data Available</div>
            </div>
        </div>
    </div>
</div>